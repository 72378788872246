/* Style for results page starts */
#results {
  .common-teaser {
    &__wrapper {
      .content-container {
        > .content {
          @include mq('large') {
            padding-bottom: 38px;
          }
        }
      }
    }

    &__disclaimer {
      @include mq('large') {
        p {
          max-width: 500px; // as per figma
        }
      }
    }
  }

  .results {
    // intro
    &__intro {
      margin-top: var(--emu-common-spacing-medium);
      margin-left: auto;
      margin-right: auto;

      // needed to make sure that the design is matching
      @include mq('medium') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        margin-top: 50px;
        max-width: 625px; // as per figma
      }

      &-title {
        .cmp-title__text {
          letter-spacing: normal;

          @include mq('large') {
            line-height: normal;
            letter-spacing: -1px;
          }
        }
      }

      &-text {
        margin-top: 20px;
        margin-bottom: 20px;

        @include mq('large') {
          margin-top: 30px;
        }
      }

      &-disclaimer {
        p {
          font-size: 14px;
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: -0.2px;
          color: var(--emu-common-colors-purple-400);
        }
      }
    }

    // cards grid
    &-card {
      &__wrapper {
        margin-top: 26px;
        padding-left: 20px;
        padding-right: 20px;
        scroll-margin-top: 60px;

        @include mq('large') {
          padding-left: 80px;
          padding-right: 80px;
          margin-top: 60px;
        }

        // show all cards on mobile when the wrapper has toggle on class
        &.js-toggle-on {
          .results-card__grid {
            > .container {
              display: block;
            }
          }
        }
      }

      &__grid {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq('large') {
          gap: 30px;
        }

        > .container {
          width: calc(50% - 10px);
          display: none;

          @include mq('large') {
            width: calc(33.33% - 20px);
            margin: auto;
            display: block;
          }

          &:has(.results-card__item--show) {
            display: block;
          }
        }
      }

      &__item {
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
          border-radius: var(--emu-common-border-radius-medium);
        }
      }

      &__title {
        font-size: 14px;
        color: var(--emu-common-colors-white);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 13px;
        position: absolute;
        display: flex;
        align-items: center;
        left: 12px;
        bottom: 9px;
        gap: 5px;

        @include mq('large') {
          font-size: 24px;
          line-height: 32px;
          bottom: 20px;
          left: 30px;
        }

        &::after {
          content: '';
          background-image: url('./assets/images/right-arrow.png');
          background-size: cover;
          width: 14px;
          height: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          display: block;

          @include mq('large') {
            width: 18px;
            height: 12px;
          }
        }
      }

      &__disclaimer {
        margin-top: 17px;
        text-align: center;
        line-height: normal;
        font-weight: 300;

        @include mq('large') {
          margin-top: 75px;
        }
      }

      &__see-more {
        margin-top: 20px;
        text-align: center;
      }
    }

    // #1, 2, 3 content
    &__data {
      padding-top: 38px;
      margin-left: auto;
      margin-right: auto;
      max-width: none;

      @include mq('large') {
        padding-top: 73px;
        padding-left: 80px;
        padding-right: 80px;
      }

      &-content-wrapper {
        margin-top: 18px;
        margin-bottom: 21px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        @include mq('large') {
          margin-top: 19px;
          margin-bottom: 18px;
          flex-direction: row;
          gap: 30px;
        }

        > .container {
          flex: 1;
        }

        h4 {
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          letter-spacing: -1px;
          margin-bottom: 11px;

          .emphasis {
            color: var(--emu-common-colors-purple-200);
          }

          @include mq('large') {
            margin-bottom: 25px;
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }
        }
      }

      &-disclaimer {
        p {
          font-size: 14px;
          line-height: 21px;
          color: var(--emu-common-colors-purple-400);
          text-indent: -5px;
        }
      }

      &-title {
        .cmp-title__text {
          line-height: normal;
        }
      }
    }

    // gradient carousel
    &__gradient-section {
      .gradient-section {
        @include mq('large') {
          margin-top: 100px;
          margin-bottom: 100px;
        }

        &__main {
          @include mq('large') {
            gap: 62px;
            padding-bottom: 59px;
          }

          > .container {
            &:first-child {
              @include mq('large') {
                width: 513px; // value from design
              }
            }

            &:last-child {
              @include mq('large') {
                width: auto;
                flex: 1;
              }
            }
          }
        }

        &__text.text {
          text-indent: -4px;
          font-size: 14px;
          line-height: 21px;
          color: var(--emu-common-colors-purple-400);

          @include mq('large') {
            max-width: 504px; // value from design
          }
        }

        &__carousel-slide-text {
          &.cmp-text {
            p {
              &:not(:last-child),
              &:only-child {
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }

    // before after carousel
    &__ba {
      padding-top: 51px;
      padding-bottom: 351px;

      // needed as the ISI content shifts and changes the height
      @include mq('431px') {
        bottom: 309px;
      }

      // needed as the ISI content shifts and changes the height
      @include mq('756px') {
        bottom: 288px;
      }

      @include mq('large') {
        padding-top: 35px;
        padding-bottom: 284px;
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        padding-left: 120px;
        padding-right: 120px;
        bottom: 210px; // height of the ISI
      }

      &-carousel {
        padding-right: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
        margin-bottom: 17px;

        @include mq('large') {
          margin-top: 35px;
          margin-bottom: 46px;
        }

        .aaaem-carousel {
          &__actions {
            position: static;
            justify-content: center;
            gap: 20px;

            @include mq('large') {
              position: absolute;
              top: 430px;
              justify-content: space-between;
              left: 0;
            }
          }

          &__action {
            position: static;
            transform: none;

            @include mq('large') {
              position: absolute;
              top: 0;
              left: 0;
              transform: translateX(-100%) translateX(-29px);
            }

            &-next {
              @include mq('large') {
                transform: translateX(100%) translateX(29px);
                left: auto;
                right: 0;
              }
            }
          }
        }
      }

      &-title .cmp-title__text {
        text-align: center;
        font-size: var(--emu-semantic-font-sizes-wide-large);
        font-weight: var(--emu-common-font-weight-bold);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -1px;
        color: var(--emu-common-colors-purple-300);
        margin-bottom: 13px;

        @include mq('large') {
          margin-bottom: 23px;
        }
      }

      &-content {
        p,
        li {
          color: var(--emu-common-colors-black);
          margin-bottom: 10px;
        }

        &-disclaimer {
          margin-bottom: 27px;

          p,
          li {
            color: var(--emu-common-colors-purple-400);
            font-size: 14px;
            line-height: var(--emu-common-line-heights-narrow-large);
            letter-spacing: -0.2px;
          }
        }

        h3 {
          margin-top: 41px;
          margin-bottom: 24px;
          font-size: 28px;
          line-height: 30px;
          letter-spacing: -1px;

          @include mq('large') {
            margin-top: 38px;
          }

          &:first-of-type {
            margin-top: 32px;

            @include mq('large') {
              margin-top: 37px;
            }
          }
        }
      }

      &-more-videos {
        &-wrapper {
          position: fixed;
          bottom: 261px;
          width: 100%;
          left: 0;
          transform: translateY(100%) translateY(-90px);
          transition: all 0.4s linear 0.4s;

          // needed as the ISI content shifts and changes the height
          @include mq('431px') {
            bottom: 219px;
          }

          // needed as the ISI content shifts and changes the height
          @include mq('756px') {
            bottom: 198px;
          }

          @include mq('large') {
            bottom: 210px;
          }

          &.js-toggle-on {
            transform: translateY(0);
            transition-delay: 0s;

            > .button {
              opacity: 0;
              pointer-events: none;
              transition: all 0.4s linear;
            }

            .results__ba-more-videos-carousel-container {
              opacity: 1;
              pointer-events: initial;
            }
          }

          > .button {
            transition: all 0.4s linear 0.8s;
            opacity: 1;
            text-align: center;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              var(--emu-common-colors-white) 100%
            );

            @include mq('large') {
              background: var(--emu-common-colors-transparent);
            }
          }
        }

        &-cta {
          gap: 26px;
          background-color: rgba(46, 24, 68, 0.8);
          padding-bottom: var(--emu-common-spacing-medium);
          padding-left: 27px;
          padding-right: 21px;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        &-carousel-container {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: var(--emu-common-spacing-xs);
          background-color: var(--emu-common-colors-purple-600);
          transition: all 0.4s linear;
          pointer-events: none;
          opacity: 0;

          @include mq('large') {
            padding-left: 18px;
            padding-top: 34px;
            padding-bottom: 34px;
          }
        }

        &-carousel {
          .active-slide img {
            border-color: var(--emu-common-colors-purple-300);
          }

          img {
            width: 100%;
            border: 6px solid var(--emu-common-colors-transparent);
            border-radius: var(--emu-common-border-radius-xs);
            cursor: pointer;
          }
        }
      }
    }

    // results modal styles
    &__modal {
      .modal-wrapper {
        background-color: var(--emu-common-colors-white);
      }

      .modal-content {
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        margin: auto;
        padding: var(--emu-common-spacing-none);
      }

      // modal close button
      .close {
        right: 0;
        z-index: var(--emu-common-other-z-index-layer);
      }
    }
  }

  // hidden modal button that gets clicked when results-card__grid-item is clicked
  .show-modal-btn {
    display: none;
  }
}

// authoring styles
.results__ba-more-videos-wrapper,
.results__ba-more-videos-cta,
.results__ba-more-videos-cta button,
.show-modal-btn {
  @include aem-editor-view {
    position: static !important;
    display: block !important;
    opacity: 1 !important;
  }
}
