body:not(#valentines-day) {
  .aaaem-accordion {
    padding-bottom: var(--emu-common-spacing-none);
  
    &__header {
      border-radius: 12px;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
      margin-bottom: 30px;
      transition: all linear
        var(--emu-component-lists-accordion-item-body-transition);
  
      &.-ba-is-active {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  
    &__button {
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
    }
  
    &__title {
      font-size: 21px;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: 1.33;
      letter-spacing: -1px;
      display: flex;
      align-items: center;
      gap: 12px;
  
      &::before {
        content: '';
        background-image: url('../../assets/images/question.png');
        width: 20px;
        height: 20px;
      }
    }
  }
}

#valentines-day {
  .aaaem-accordion {
    &__header {
      &:not(.-ba-is-active) {
        border-bottom-color: var(--emu-component-lists-accordion-item-header-color-border-bottom-closed-light);
      }
    }
  }
}