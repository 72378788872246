.emu-mapping-marker {
  span {
    pointer-events: none;
    &::after,
    &::before {
      display: none;
    }
  }
}

.emu-image-map {
  width: 100%;
}
