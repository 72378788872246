/*********  common teaser styling starts *********/
.common-teaser {
  &__wrapper {
    position: relative;
    padding-bottom: 25px;

    .aaaem-teaser {
      position: relative;
      background-position: center;

      .cmp-teaser {
        &__title {
          text-align: center;
          margin-bottom: 12px;
          letter-spacing: -2px; //as per figma
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-common-line-heights-narrow-xxl);
          font-weight: var(--emu-common-font-weight-bold);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxxl);
            line-height: var(--emu-semantic-line-heights-wide-xxxl);
            text-align: left;
            letter-spacing: -3.5px; //as per figma
          }

          sup {
            font-size: 18px;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              font-weight: var(--emu-common-font-weight-light);
            }
          }
        }

        &__description {
          margin-left: 20px;
          margin-right: 20px;

          @include mq('large') {
            display: flex;
            margin: var(--emu-common-spacing-none);
          }

          br {
            display: none;
            @include mq('large') {
              display: block;
            }
          }

          p {
            text-align: center;
            margin: var(--emu-common-spacing-none);
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('large') {
              text-align: left;
            }

            sup {
              font-size: 10px;
            }
          }
        }
      }

      .content-container {
        margin-top: 81px;
        margin-bottom: 155px;
        margin-left: 20px;
        margin-right: 20px;

        @include mq('large') {
          display: flex;
          align-items: center;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }

        > .content {
          @include mq('large') {
            width: 100%;
            margin-left: 30px;
            max-width: 578px; //as per figma
            padding-top: 122px;
            height: 100%;
            display: flex;
            justify-content: center;
            margin-top: 50px;
            margin-bottom: 44px;
          }

          @include mq('x-large') {
            margin-left: 80px;
          }

          @include mq('xx-large') {
            margin-left: 189px;
          }

          .title-lockup {
            @include mq('large') {
              margin-left: var(--emu-common-spacing-none);
            }

            br {
              display: none;

              @include mq('large') {
                display: block;
              }
            }
          }
        }
      }
    }

    > div.image {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @include mq('large') {
        transform: none;
        right: 0;
        left: auto;
      }
    }
  }

  &__hero-image {
    img {
      margin-left: auto;
      margin-right: auto;
      max-width: 157px; //as per figma
      border-radius: 20px;
    }

    @include mq('large') {
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      margin-left: auto;
      margin-right: auto;

      img {
        max-width: 260px; //as per figma
        margin-right: 30px;
        margin-left: auto;
      }
    }

    @include mq('x-large') {
      img {
        margin-right: 80px;
      }
    }
    @include mq('xx-large') {
      img {
        margin-right: 298px;
      }
    }
  }

  &__disclaimer.text {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
      text-align: left;
    }

    &.aaaem-text {
      p {
        margin-left: 20px;
        margin-right: 20px;

        @include mq('large') {
          margin-left: 30px;
          width: 625px; //as per figma
          margin-right: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          margin-left: 80px;
        }

        @include mq('xx-large') {
          margin-left: 189px;
        }
      }
    }
  }
}

/*********  common teaser styling end *********/

/*********  common card component styling starts *********/
.botox-cards {
  padding-top: 40px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  @include mq('large') {
    padding-top: 100px;
    padding-right: 79px;
    padding-bottom: 96px;
    padding-left: 80px;
    display: flex;
    gap: 6px 30px;
    flex-wrap: wrap;

    > .container {
      width: calc(33.33% - 20px); // 20px is the spacing 60px divided by 2 gaps
    }

    > .text {
      flex: 3;
    }
  }

  &__disclaimer.text {
    font-size: 14px;
    line-height: 21px;

    .emphasis {
      display: inline-block;
    }
  }

  &__item {
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    border-radius: var(--emu-common-border-radius-medium);
    margin-bottom: 20px;
    text-align: center;

    @include mq('large') {
      padding-top: 50px;
      padding-right: 45px;
      padding-bottom: 51px;
      padding-left: 50px;
      margin-bottom: var(--emu-common-spacing-none);
      display: flex;
      flex-direction: column;
      text-align: left;
      height: 100%;
    }

    &--purple {
      background-color: var(--emu-common-colors-purple-100);
    }

    &--gold {
      background-color: var(--emu-common-colors-gold-100);
    }

    &--gray {
      background-color: var(--emu-common-colors-medium-grey-300);
    }

    > .button {
      @include mq('large') {
        margin-top: auto;
      }
    }

    &-image {
      margin-bottom: 10px;

      svg,
      img {
        width: 28px; // to match the design
      }

      @include mq('large') {
        margin-bottom: 25px;
      }
    }

    &-title .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-wide-xl);
      line-height: normal;
      font-weight: var(--emu-common-font-weight-regular);
      color: var(--emu-common-colors-purple-300);
      letter-spacing: -1px;
      margin-bottom: 18px;
      text-align: center;

      @include mq('large') {
        margin-bottom: 21px;
        text-align: left;
      }
    }

    &-text.cmp-text {
      padding-bottom: 26px;
      color: var(--emu-common-colors-purple-400);

      @include mq('large') {
        margin-bottom: 54px;
      }

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    &-cta {
      padding: var(--emu-common-spacing-medium) var(--emu-common-spacing-large);
    }
  }
}
/*********  common card component styling ends *********/

/* Added css for common component botox order */
.botox-order-wrapper {
  background: var(--emu-common-colors-gold-200);
  padding-top: var(--emu-common-spacing-large);
  padding-left: 18px;
  padding-right: 22px;

  @include mq('large') {
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  .container--inner {
    .botox-order-wrapper__item-img {
      position: relative;

      .image:nth-child(2) {
        position: absolute;
        right: 25px;
        bottom: 20px;
      }
    }
    @include mq('large') {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: auto;
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);

      > .container:nth-child(1) {
        padding-left: 80px;
        padding-top: 112px;
        padding-bottom: 124px;
        max-width: 60%;
      }

      > .container:nth-child(2) {
        flex: 1;
      }

      .botox-order-wrapper__item-img {
        position: relative;

        .image:nth-child(1) {
          max-height: 439px;
          overflow: hidden;
        }
      }
    }
  }

  &__item-text {
    text-align: center;

    @include mq('large') {
      text-align: left;
    }
  }

  .title .botox-order-wrapper__title .cmp-title__text {
    color: var(--emu-common-colors-purple-300);
    font-size: var(--emu-common-font-sizes-narrow-xl);
    font-weight: var(--emu-common-font-weight-bold);
    line-height: 32px;
    letter-spacing: -1px;
    margin-bottom: 17px;
    text-align: center;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-xxl);
      line-height: normal;
      text-align: left;
    }
  }

  .text .botox-order-wrapper__text {
    p {
      color: var(--emu-common-colors-black);
      font-size: 21px;
      font-style: normal;
      font-weight: var(--emu-common-font-weight-light);
      line-height: 30px;
      margin-bottom: var(--emu-common-spacing-large);
    }

    a {
      &,
      &:hover,
      &:focus,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__btn {
    color: var(--emu-common-colors-white);
    font-size: var(--emu-common-font-sizes-wide-medium);
    font-weight: var(--emu-common-font-weight-bold);
    line-height: normal;
    border-radius: 20px;
    border: var(--emu-common-border-width-none);
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--emu-common-colors-purple-200);
  }
}

/* Common carousel component styes start */
.gradient-section {
  margin-top: 50px;
  margin-bottom: 50px;

  .emphasis {
    display: inline-block;
    text-indent: initial;
  }

  &__main {
    border-radius: var(--emu-common-border-radius-medium);
    padding-top: var(--emu-common-spacing-large);
    padding-right: 20px;
    padding-bottom: 36px;
    padding-left: 20px;

    @include mq('large') {
      display: flex;
      column-gap: 101px;
      padding-top: 62px;
      padding-right: 38px;
      padding-bottom: 65px;
      padding-left: 42px;
    }

    > .container {
      @include mq('large') {
        &:first-child {
          width: calc(53% - 50px); // needed to match design
          @include aem-editor-view {
            min-width: 400px;
          }
        }

        &:last-child {
          width: calc(47% - 51px); // needed to match design
        }
      }
    }
  }

  &__title .cmp-title__text {
    margin-bottom: 23px;
    color: var(--emu-common-colors-purple-300);
    font-size: var(--emu-common-font-sizes-narrow-xl);
    font-weight: var(--emu-common-font-weight-bold);
    line-height: var(--emu-semantic-line-heights-narrow-xl);
    letter-spacing: -1px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-xl);
      line-height: normal;
      margin-bottom: 19px;
    }
  }

  &__text.text {
    color: var(--emu-common-colors-black);
    font-size: 18px;
    font-weight: var(--emu-common-font-weight-light);
    line-height: var(--emu-common-line-heights-wide-large);
    margin-bottom: 19px;

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &__carousel {
    &.aaaem-carousel {
      padding-top: 36px;
    }

    &-slide {
      display: flex;
      gap: 7px;

      &-image {
        width: 21px; // needed to match the design
        margin-top: 19px;

        @include aem-editor-view {
          width: 100px;
        }
      }

      &-title .cmp-title__text {
        color: var(--emu-common-colors-purple-300);
        font-size: 56px;
        line-height: normal;
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: -1px;
      }

      &-text.cmp-text {
        color: var(--emu-common-colors-purple-400);
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        font-weight: var(--emu-common-font-weight-light);
        line-height: 28px;

        p:not(:last-child),
        p:only-child {
          margin-bottom: 10px;
        }
      }

      &-desc.cmp-text {
        color: var(--emu-common-colors-purple-400);
        font-size: 14px;
        font-weight: var(--emu-common-font-weight-light);
        line-height: 21px;
        margin-bottom: var(--emu-semantic-spacing-vertical);

        p:not(:last-child):not(:only-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
/* Common carousel component styes end */

// flippable card carousel
.flippable-card__carousel {
  .aaaem-carousel__content {
    display: flex;

    .tns-item {
      flex: 1;
    }

    .carouselslide,
    .aaaem-carousel__item-container,
    .aaaem-carousel__item-container > .aem-Grid,
    .flippablecard,
    .flippable-card,
    .flippable-card__wrapper {
      height: 100%;
    }
    .flippable-card {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

// table styles
table {
  border: var(--emu-common-border-width-none);
  width: 100%;
  border-collapse: collapse;

  @include mq('large') {
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--emu-common-colors-purple-100);
  }

  thead {
    display: none;
    @include mq('large') {
      display: table-header-group;
    }
  }

  tbody {
    display: block;
    @include mq('large') {
      display: table-row-group;
    }
  }

  .emphasis {
    display: inline-block;
  }

  .title {
    font-weight: var(--emu-common-font-weight-bold);
    min-width: min(173px, calc(50% - 10px)); // from design

    @include mq('large') {
      display: none;
    }
  }

  .content {
    min-width: min(calc(100% - 173px - 20px), calc(50% - 10px));

    @include mq('large') {
      width: auto;
      min-width: 0;
    }
  }

  tr {
    margin-top: 20px;
    display: block;
    border-radius: 20px;
    overflow: hidden;

    @include mq('large') {
      display: table-row;
      border-radius: var(--emu-common-border-radius-none);
      margin: var(--emu-common-spacing-none);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-white);
    }

    td,
    th {
      @include mq('large') {
        border: none;

        &:not(:first-child) {
          text-align: center;
        }

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
    }

    th {
      background-color: var(--emu-common-colors-purple-300);
      color: var(--emu-common-colors-white);
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
    }

    td {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      text-align: left;
      border: none;
      gap: 20px;
      justify-content: space-between;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-white);
      background-color: var(--emu-common-colors-purple-100);
      color: var(--emu-common-colors-purple-400);

      @include mq('large') {
        display: table-cell;
        padding: var(--emu-common-spacing-none);
        border-bottom: none;
        padding-top: 29px;
        padding-bottom: 27px;
        background-color: var(--emu-common-colors-transparent);
      }

      &:first-child {
        background-color: var(--emu-common-colors-purple-300);
        color: var(--emu-common-colors-white);
        padding-top: 16px;
        padding-bottom: 12px;
        display: block;

        @include mq('large') {
          display: table-cell;
          background-color: var(--emu-common-colors-transparent);
          color: var(--emu-common-colors-purple-400);
        }

        .title {
          display: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// references section
.references.text {
  margin-top: 45px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--emu-common-colors-purple-400);
  word-wrap: break-word;

  @include mq('large') {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 44px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .emphasis {
    display: inline-block;
  }

  ul {
    margin: var(--emu-common-spacing-none);
    padding: var(--emu-common-spacing-none);

    li {
      list-style: none;
      text-indent: -4px;
    }

    sup {
      top: 2px;
    }
  }
}

// tabs with select in mobile
.custom-tabs {
  &__select-wrapper {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      display: none;
    }
  }

  .aaaem-tabs__tablist {
    @include mq('large', 'max-width') {
      display: none;
    }
  }
}

// image map section
.img-map {
  position: relative;

  .emu-mapping-marker {
    transition: all 0.4s linear;
  }

  &.img-map--rendered {
    .emu-mapping-marker {
      &--active {
        span {
          svg {
            circle {
              fill: var(--emu-common-colors-purple-300);
            }

            path {
              fill: var(--emu-common-colors-white);
            }
          }
        }
      }
    }
  }

  &__content {
    position: absolute;
    transform: translateY(48px) translateX(-32px);
    opacity: 0;
    pointer-events: none;

    @include aem-editor-view {
      transform: scale(1) !important;
      opacity: 1 !important;
      position: static !important;
    }

    &::before {
      content: '';
      width: 26px;
      height: 18px;
      position: absolute;
      top: 0;
      left: 34px;
      background-image: url('./assets/images/tooltip-arrow.png');
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateY(-100%);
      opacity: 0;
      transition: opacity 0.4s linear;
    }

    &-inner {
      opacity: 0;
      transition: all 0.4s linear;
      padding: var(--emu-common-spacing-medium);
      background-color: var(--emu-common-colors-purple-500);
      display: flex;
      gap: var(--emu-common-spacing-medium);
      border-radius: var(--emu-common-border-radius-medium);
      flex-direction: column;
      position: relative;

      @include mq('large') {
        padding: 30px;
        flex-direction: row;
        gap: 30px;
      }

      @include aem-editor-view {
        opacity: 1 !important;
      }

      .cmp-image {
        width: 186px; // as per figma
        height: 176px; // as per figma
      }

      .cmp-text {
        max-width: 230px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          color: var(--emu-common-colors-white);
          line-height: var(--emu-common-line-heights-wide-large);
          letter-spacing: -1px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-medium);
          }
        }

        p {
          margin-top: 12px;
          color: var(--emu-common-colors-white);
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-large);
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
          }
        }
      }
    }

    &--active {
      opacity: 1;
      pointer-events: initial;

      &::before,
      .img-map__content-inner {
        opacity: 1;
      }
    }

    &--right-top {
      transform: translateY(48px) translateX(-100%) translateX(62px);

      &::before {
        left: auto;
        right: 34px;
      }
    }

    &--right-bottom {
      transform: translateY(-100%) translateY(-24px) translateX(-100%)
        translateX(62px);

      &::before {
        top: auto;
        bottom: 0;
        right: 34px;
        left: auto;
        transform: translateY(100%) rotate(180deg);
      }
    }

    &--left-bottom {
      transform: translateY(-100%) translateY(-24px) translateX(-32px);

      &::before {
        bottom: 0;
        top: auto;
        transform: translateY(100%) rotate(180deg);
      }
    }
  }

  &__disclaimer.cmp-text {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    color: var(--emu-common-colors-white);
    font-family: var(--emu-semantic-font-families-suisse-intl-regular);
    font-size: var(--emu-common-font-sizes-narrow-medium);
    font-weight: 450;
    line-height: 145%;
    text-align: center;

    @include aem-editor-view {
      position: static !important;
      color: var(--emu-common-colors-black) !important;
    }

    @include mq('x-large') {
      text-align: right;
      bottom: 22px;
      padding-right: 38px;
      padding-left: 20px;
      font-size: 11px;
    }
  }
}

/* common 3 areas hot-spot image map section start */

.areas-hotspot {
  background-color: var(--emu-semantic-colors-secondary-dark-gray-400);
  padding-top: 44px;
  padding-bottom: 46px;

  @include mq('large') {
    padding-top: 61px;
    padding-bottom: 70px;
  }

  &__title {
    margin-bottom: 20px;

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
      margin-left: -12px;
      margin-right: -12px;
    }

    h3,
    h4,
    h5 {
      letter-spacing: normal;

      @include mq('large') {
        letter-spacing: -1px;
      }
    }
  }

  &__sub-title {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: 30px;
    }

    h4,
    h5,
    h3 {
      font-weight: var(--emu-common-font-weight-regular);
      line-height: 28px;
      letter-spacing: -1px;
    }
  }

  &__3areas-img {
    position: absolute;
    top: var(--emu-common-spacing-medium);
    left: var(--emu-common-spacing-medium);

    @include mq('large') {
      top: 40px;
      left: 40px;
    }

    img {
      width: 70px; // as per figma

      @include mq('large') {
        width: 175px; // as per figma
      }
    }
  }

  .img-map {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 844px; // from design
    max-width: 100%;

    @include mq('large') {
      margin-bottom: 44px;
    }

    &-image {
      img {
        border-radius: var(--emu-common-border-radius-small);
        width: 100%;

        @include mq('large') {
          border-radius: var(--emu-common-border-radius-medium);
        }
      }
    }

    &__content {
      &-inner {
        padding-top: 12px;
        padding-bottom: 14px;

        @include mq('large') {
          padding-top: 20px;
          padding-bottom: 24px;
        }

        h4 {
          line-height: var(--emu-semantic-line-heights-narrow-medium);
        }

        p {
          margin-top: -4px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-xs);
          }
        }
      }

      &--lateral-canthal-lines-left,
      &--lateral-canthal-lines-right {
        .cmp-text {
          width: 238px; // as per figma
          max-width: fit-content;

          @include mq('large') {
            width: 270px; // as per figma
          }
        }
      }
    }
  }

  &__disclaimer {
    @include mq('large') {
      max-width: 843px;
      margin-left: auto;
      margin-right: auto;
    }
    &.aaaem-text {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        margin-bottom: 35px;

        @include mq('large') {
          margin-bottom: 33px;
        }

        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);
          max-width: 625px;
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            margin-bottom: 33px;
          }
        }
      }
    }
  }
}
