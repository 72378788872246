.skip-menu-container a.skip-menu {
  position: absolute;
}

div.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);

  //remove initial styles for button
  .aaaem-button,
  .aaaem-button:hover,
  .aaaem-button:active,
  .aaaem-button:focus {
    padding: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-transparent);
    border: var(--emu-common-border-width-none);
    outline: var(--emu-common-outline-width-unset);

    .cmp-button__text {
      padding-right: var(--emu-common-spacing-xs);
    }

    .chevron-down {
      transition: none;
    }
  }

  #aesthetics-brands-close {
    margin-top: 12px;
  }

  .header {
    &__menu {
      &-mobile {
        display: flex;
        justify-content: space-between;
        padding-left: 11px;
        padding-right: 20px;
        padding-top: var(--emu-common-spacing-medium);
        padding-bottom: 10px;

        @include mq('large') {
          padding: var(--emu-common-spacing-none);

          > div {
            &.image,
            &.button {
              display: none;
            }
          }
        }

        &-logo,
        &-logo-light {
          img {
            width: 89px; // to match the design.

            @include mq('large') {
              width: 135px; // to match the design.
            }
          }
        }

        &-logo-light {
          display: none;
        }

        .button {
          height: max-content;
        }

        > div.container {
          position: absolute;

          @include mq('large') {
            position: relative;
            width: 100%;
          }

          .header__menu-mobile-dropdown {
            display: none;
            transition: all 0.4s linear;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--emu-common-colors-purple-100);
            overflow-y: auto;

            @include mq('large') {
              background-color: var(--emu-common-colors-transparent);
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding-left: 30px;
              padding-right: 30px;
              margin-top: 77px;
              height: auto;
            }

            @include mq('x-large') {
              padding-left: 69px;
              padding-right: 80px;
            }

            .emu-navigation__content-wrapper {
              @include mq('large') {
                z-index: 1;
                margin-top: 18px;
              }
            }

            > div.container {
              &:last-child {
                @include mq('large') {
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  z-index: 2;
                }
              }
            }

            &.js-toggle-on {
              display: block;
            }
          }
        }

        &-dropdown {
          &-top-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-left: 11px;
            padding-right: 20px;
            padding-top: var(--emu-common-spacing-medium);
            padding-bottom: 10px;

            @include mq('large') {
              padding: var(--emu-common-spacing-none);
            }

            > div.button {
              display: flex;
              margin-top: var(--emu-common-spacing-xs);

              @include mq('large') {
                display: none;
              }
            }

            &-logo-light {
              display: none;
            }
          }

          > div.button {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            @include mq('large') {
              margin: var(--emu-common-spacing-none);
              margin-top: 13px;
            }

            .aaaem-button {
              background-color: var(--emu-common-colors-purple-200);
              border-radius: 20px;
              padding-top: var(--emu-component-actions-button-padding-top-wide);
              padding-right: var(--emu-common-spacing-large);
              padding-bottom: var(
                --emu-component-actions-button-padding-bottom-wide
              );
              padding-left: var(--emu-common-spacing-large);
              margin-right: 40px;
              margin-bottom: 42px;
              margin-left: 40px;
              width: 100%;
              display: flex;
              justify-content: center;
              line-height: normal;

              @include mq('large') {
                margin: var(--emu-common-spacing-none);
                padding-left: 20px;
                padding-right: 20px;
              }

              .cmp-button__text {
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }

          &-bottom-content {
            background-color: var(--emu-common-colors-purple-300);
            padding-top: 31px;
            padding-bottom: 31px;
            padding-left: 40px;
            padding-right: 40px;

            @include mq('large') {
              display: flex;
              align-items: center;
              padding-top: 10px;
              padding-bottom: var(--emu-common-spacing-small);
              padding-left: 30px;
              padding-right: 30px;
            }

            @include mq('x-large') {
              padding-left: 80px;
              padding-right: 80px;
              max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
              margin-left: auto;
              margin-right: auto;
              position: relative;
            }

            > div.button {
              .aaaem-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 19px;
                font-weight: var(--emu-common-font-weight-regular);
                font-size: 18px;

                @include mq('large') {
                  margin: var(--emu-common-spacing-none);
                  font-size: 14px;
                  line-height: normal;
                }

                &.js-toggle-on {
                  margin-bottom: var(--emu-common-spacing-none);
                }

                .chevron,
                .chevron-down {
                  margin-left: var(--emu-common-spacing-none);
                }

                .chevron {
                  width: auto;
                  height: auto;

                  &-down {
                    min-width: auto;
                    min-height: auto;
                    width: 16px; // to match the design.
                    stroke: var(--emu-common-colors-white);

                    @include mq('large') {
                      width: 10px;
                    }
                  }
                }
              }
            }

            > div.container {
              z-index: var(--emu-common-other-z-index-layer);

              &:last-child {
                z-index: var(
                  --emu-common-other-z-index-base
                ); // to match the design.
              }
            }

            &-brands-content {
              display: none;

              &.js-toggle-on {
                display: block;

                @include mq('large') {
                  background-color: var(
                    --emu-common-colors-purple-300
                  );
                  display: flex;
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                }

                > .text {
                  @include mq('large') {
                    width: 100%;
                  }
                }

                ul {
                  @include mq('large') {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    padding: var(--emu-common-spacing-none);
                    margin: var(--emu-common-spacing-none);
                  }
                }

                li {
                  @include mq('large') {
                    margin-top: var(--emu-common-spacing-small);
                    margin-right: 12px;
                    margin-bottom: var(--emu-common-spacing-small);
                    margin-left: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    right: -12px;
                    top: 5px;
                    width: 1px;
                    height: 29px;
                    background-color: var(
                      --emu-common-colors-purple-100
                    );
                  }

                  &:nth-child(4n) {
                    &::after {
                      display: none;
                    }
                  }

                  a {
                    @include mq('large') {
                      text-align: center;
                    }
                  }
                }

                > .button {
                  display: none;

                  @include mq('large') {
                    display: block;
                    position: absolute;
                    top: -4px; // to match the design,
                    right: var(--emu-common-sizing-xs);
                  }
                }
              }

              ul {
                margin-top: 18px;
                padding-left: 20px;

                li {
                  margin-bottom: 17px;
                  line-height: 14px;
                  list-style: none;
                  a {
                    font-size: 18px;
                    color: var(--emu-common-colors-white);
                    text-decoration: none;

                    @include mq('large') {
                      font-size: 14px;
                      line-height: normal;
                    }

                    sup {
                      top: 1px;

                      @include mq('large') {
                        top: 0px;
                      }
                    }

                    .emphasis {
                      font-size: var(--emu-common-font-sizes-narrow-medium);

                      @include mq('large') {
                        font-size: 10px;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }

            &-text {
              ul {
                margin: var(--emu-common-spacing-none);
                padding: var(--emu-common-spacing-none);

                @include mq('large') {
                  display: flex;
                  flex-direction: row;
                }

                li {
                  list-style: none;
                  padding-bottom: 19px;
                  font-size: 18px;
                  line-height: 24px;
                  color: var(--emu-common-colors-white);

                  @include mq('large') {
                    padding: var(--emu-common-spacing-none);
                    font-size: 14px;
                    line-height: normal;
                  }

                  &:first-child {
                    @include mq('large') {
                      margin-right: 38px;
                      margin-left: 31px;
                    }

                    @include mq('x-large') {
                      margin-right: 38px;
                      margin-left: 61px;
                    }
                  }

                  a,
                  a:visited {
                    color: inherit;
                    text-decoration: none;
                  }
                }

                > li:last-child {
                  border-bottom: var(--emu-common-border-width-thin) solid
                    var(--emu-common-colors-white);

                  @include mq('large') {
                    border: none;
                    margin: var(--emu-common-spacing-none);
                  }
                }
              }
            }

            &-social-links {
              display: flex;
              justify-content: space-between;
              max-width: 85px; // to match the design.
              width: 100%;
              margin-top: 24px;

              @include mq('large') {
                margin: var(--emu-common-spacing-none);
                position: absolute;
                top: 0;
                right: 30px;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              @include mq('x-large') {
                right: 80px;
              }

              div.image {
                .aaaem-image {
                  width: 17px; //to match figma
                }
              }
            }
          }
        }

        > .button {
          @include mq('large') {
            display: none;
          }

          .aaaem-button {
            padding-top: 7px; //needed to match figma
            padding-bottom: 7px; //needed to match figma
            position: relative;

            span {
              height: 2px; //needed to match figma
              width: 20px; //to match live hamburger
              background: var(--emu-common-colors-black);
              border-radius: 30px;

              &::before,
              &::after {
                content: '';
                background: inherit;
                height: inherit;
                width: inherit;
                position: absolute;
                right: 0;
              }

              &::before {
                top: 0;
              }

              &::after {
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
