// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.container--large {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;
}

//background color
.bg--dark-purple {
  background-color: var(--emu-common-colors-purple-400);
}

.bg--purple {
  background-color: var(--emu-common-colors-purple-300);
}

.bg--light-purple {
  background-color: var(--emu-common-colors-purple-200);
}

.bg--gray-purple {
  background-color: var(--emu-common-colors-purple-100);
}

.bg--dark-gray {
  background-color: var(--emu-semantic-colors-secondary-dark-gray-100);
}

.bg--light-gray {
  background-color: var(--emu-common-colors-light-grey-400);
}

.bg--light-gold {
  background-color: var(--emu-common-colors-gold-100);
}

.bg--light-gradient {
  background: linear-gradient(
    180deg,
    var(--emu-common-colors-gold-200) 0%,
    rgba(187, 169, 205, 0.54) 27.2%,
    rgba(149, 119, 191, 0.37) 50.8%,
    rgba(185, 171, 213, 0.63) 75.73%,
    var(--emu-common-colors-purple-100) 100%
  );
}

// text related sizes and colors
[data-component='text'],
[data-component='title'] {
  &[class*='text--color-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-dark-purple {
    color: var(--emu-common-colors-purple-400);
  }

  &.text--color-purple {
    color: var(--emu-common-colors-purple-300);
  }

  &.text--color-light-purple {
    color: var(--emu-common-colors-purple-200);
  }
}

//title with font size 32px in mobile & 48px in desktop
.heading-primary {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
    font-weight: var(--emu-common-font-weight-bold);
    letter-spacing: -1px;
    color: var(--emu-common-colors-purple-300);

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-xxl);
      line-height: var(--emu-semantic-line-heights-wide-xxl);
    }
  }

  .emphasis {
    display: inline-block;
  }
}

// most of the containers have a max-width of 1062px
.inner-container {
  padding-left: 20px;
  padding-right: 20px;

  // media query needed to make sure that there is some spacing around the container
  @include mq('1102px') {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  @include mq('large') {
    max-width: 1062px;
    margin-left: auto;
    margin-right: auto;
  }

  &--small {
    @include mq('large') {
      max-width: 843px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
