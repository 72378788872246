.aaaem-teaser {
  .teaser-image-container {
    .cmp-teaser {
      &__image--mobile {
        display: block;

        @include mq('large') {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      &__image--desktop {
        display: none;

        @include mq('large') {
          display: block;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  .aaaem-teaser {
    &__action-link {
      // css from button V2
      background-color: var(--emu-component-actions-button-color-background-primary-filled-default-light);
      border-radius: var(--emu-component-actions-button-border-radius-primary-filled);
      border-color: var(--emu-component-actions-button-color-border-primary-filled-default-light);
      border-style: solid;
      border-width: var(--emu-component-actions-button-border-width-primary-filled);
      color: var(--emu-component-actions-button-text-color-primary-filled-default-light);
      display: inline-flex;
      outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
      padding: var(--emu-component-actions-button-padding-narrow);
      text-decoration: none;
      .emu-theme__dark & {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
      }
  
      @include mq($emu-semantic-sizing-breakpoints-medium) {
        padding: var(--emu-component-actions-button-padding-wide);
      }
  
      svg {
        fill: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
        .emu-theme__dark & {
          fill: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
        }
      }
      
      svg,
      .emu-button__icon,
      .chevron {
        margin-bottom: auto;
        margin-right: var(--emu-component-actions-button-icon-spacing);
        margin-top: auto;
      }
      &.caret-icon,
      &.plus-minus-icon {
        .emu-button__icon {
          &::before,
          &::after {
            background-color: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
            .emu-theme__dark & {
              background-color: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
            }
          }
        }
      }
  
      &:focus {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-focus-light);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-focus-light);
        color: var(--emu-component-actions-button-text-color-primary-filled-focus-light);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-focus-light);
        .emu-theme__dark & {
          background-color: var(--emu-component-actions-button-color-background-primary-filled-focus-dark);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-focus-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-focus-dark);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-focus-dark);
        }
        svg {
          fill: var(--emu-component-actions-button-icon-color-primary-filled-focus-light);
          .emu-theme__dark & {
            fill: var(--emu-component-actions-button-icon-color-primary-filled-focus-dark);
          }
        }
        &.caret-icon,
        &.plus-minus-icon {
          .emu-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-component-actions-button-icon-color-primary-filled-focus-light);
              .emu-theme__dark & {
                background-color: var(--emu-component-actions-button-icon-color-primary-filled-focus-dark);
              }
            }
          }
        }
      }
  
      &:hover {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-hover-light);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-hover-light);
        color: var(--emu-component-actions-button-text-color-primary-filled-hover-light);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-hover-light);
        .emu-theme__dark & {
          background-color: var(--emu-component-actions-button-color-background-primary-filled-hover-dark);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-hover-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-hover-dark);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-hover-dark);
        }
        svg {
          fill: var(--emu-component-actions-button-icon-color-primary-filled-hover-light);
          .emu-theme__dark & {
            fill: var(--emu-component-actions-button-icon-color-primary-filled-hover-dark);
          }
        }
        &.caret-icon,
        &.plus-minus-icon {
          .emu-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-component-actions-button-icon-color-primary-filled-hover-light);
              .emu-theme__dark & {
                background-color: var(--emu-component-actions-button-icon-color-primary-filled-hover-dark);
              }
            }
          }
        }
      }
  
      &:active {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-active-light);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-active-light);
        color: var(--emu-component-actions-button-text-color-primary-filled-active-light);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-active-light);
        outline-width: var(--emu-component-actions-button-outline-width-primary-filled);
        outline-style: solid;
        .emu-theme__dark & {
          background-color: var(--emu-component-actions-button-color-background-primary-filled-active-dark);
          border-color: var(--emu-component-actions-button-color-border-primary-filled-active-dark);
          color: var(--emu-component-actions-button-text-color-primary-filled-active-dark);
          outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-active-dark);
        }
        svg {
          fill: var(--emu-component-actions-button-icon-color-primary-filled-active-light);
          .emu-theme__dark & {
            fill: var(--emu-component-actions-button-icon-color-primary-filled-active-dark);
          }
        }
        &.caret-icon,
        &.plus-minus-icon {
          .emu-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-component-actions-button-icon-color-primary-filled-active-light);
              .emu-theme__dark & {
                background-color: var(--emu-component-actions-button-icon-color-primary-filled-active-dark);
              }
            }
          }
        }
      }
      &:disabled {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-disabled-light);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-disabled-light);
        color: var(--emu-component-actions-button-text-color-primary-filled-disabled-light);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-disabled-light);
        outline-width: var(--emu-component-actions-button-outline-width-primary-filled);
        outline-style: solid;
        .emu-theme__dark & {
          background-color: var(--emu-component-actions-button-color-background-primary-filled-disabled-dark);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-disabled-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-disabled-dark);
        outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-disabled-dark);
        }
        svg {
          fill: var(--emu-component-actions-button-icon-color-primary-filled-disabled-light);
          .emu-theme__dark & {
            fill: var(--emu-component-actions-button-icon-color-primary-filled-disabled-dark);
          }
        }
        &.caret-icon,
        &.plus-minus-icon {
          .emu-button__icon {
            &::before,
            &::after {
              background-color: var(--emu-component-actions-button-icon-color-primary-filled-disabled-light);
              .emu-theme__dark & {
                background-color: var(--emu-component-actions-button-icon-color-primary-filled-disabled-dark);
              }
            }
          }
        }
      }
    }
  }
}

