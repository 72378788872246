
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-common-spacing-xs: 4px !default;
$emu-common-spacing-xxs: 2px !default;
$emu-common-spacing-small: 8px !default;
$emu-common-spacing-medium: 16px !default;
$emu-common-spacing-large: 32px !default;
$emu-common-spacing-xl: 64px !default;
$emu-common-spacing-none: 0px !default;
$emu-common-spacing-unset: unset !default;
$emu-common-colors-white: #FFFFFF !default;
$emu-common-colors-black: #160C1F !default;
$emu-common-colors-transparent: rgba(0,0,0,0) !default;
$emu-common-colors-red-100: #FF7570 !default;
$emu-common-colors-red-200: #FF615C !default;
$emu-common-colors-red-300: #FF4E47 !default;
$emu-common-colors-red-400: #FF3A33 !default;
$emu-common-colors-red-500: #FF1D15 !default;
$emu-common-colors-red-600: #FF120A !default;
$emu-common-colors-red-700: #F50800 !default;
$emu-common-colors-red-800: #E00700 !default;
$emu-common-colors-red-900: #CC0700 !default;
$emu-common-colors-grey-50: #FFFFFF !default;
$emu-common-colors-grey-100: #FFFFFF !default;
$emu-common-colors-grey-200: #F5F5F5 !default;
$emu-common-colors-grey-300: #EDEDEE !default;
$emu-common-colors-grey-400: #E3E2E4 !default;
$emu-common-colors-grey-500: #DAD9DB !default;
$emu-common-colors-grey-600: #ADABB0 !default;
$emu-common-colors-grey-700: #827F85 !default;
$emu-common-colors-grey-800: #827F85 !default;
$emu-common-colors-grey-900: #2B2A2D !default;
$emu-common-colors-grey-950: #171618 !default;
$emu-common-colors-teal-100: #00abc7 !default;
$emu-common-colors-teal-200: #00abc7 !default;
$emu-common-colors-teal-300: #00abc7 !default;
$emu-common-colors-teal-400: #00abc7 !default;
$emu-common-colors-teal-500: #00abc7 !default;
$emu-common-colors-teal-600: #00acc8 !default;
$emu-common-colors-teal-700: #00abc7 !default;
$emu-common-colors-teal-800: #00abc7 !default;
$emu-common-colors-teal-900: #00abc7 !default;
$emu-common-colors-beige-100: #fcf7f6 !default;
$emu-common-colors-beige-200: #faf0ec !default;
$emu-common-colors-beige-300: #f7e8e3 !default;
$emu-common-colors-beige-400: #f5e1d9 !default;
$emu-common-colors-beige-500: #f2d9d0 !default;
$emu-common-colors-beige-600: #c2aea6 !default;
$emu-common-colors-beige-700: #91827d !default;
$emu-common-colors-beige-800: #615753 !default;
$emu-common-colors-beige-900: #302b2a !default;
$emu-common-colors-light-brown-100: #f4ebe8 !default;
$emu-common-colors-light-brown-200: #ead7d2 !default;
$emu-common-colors-light-brown-300: #dfc2bb !default;
$emu-common-colors-light-brown-400: #d5aea5 !default;
$emu-common-colors-light-brown-500: #ca9a8e !default;
$emu-common-colors-light-brown-600: #a27b72 !default;
$emu-common-colors-light-brown-700: #795c55 !default;
$emu-common-colors-light-brown-800: #513e39 !default;
$emu-common-colors-light-brown-900: #281f1c !default;
$emu-common-colors-medium-brown-100: #e9dedd !default;
$emu-common-colors-medium-brown-200: #d3bebb !default;
$emu-common-colors-medium-brown-300: #be9d98 !default;
$emu-common-colors-medium-brown-400: #a87d76 !default;
$emu-common-colors-medium-brown-500: #925c54 !default;
$emu-common-colors-medium-brown-600: #754a43 !default;
$emu-common-colors-medium-brown-700: #583732 !default;
$emu-common-colors-medium-brown-800: #3a2522 !default;
$emu-common-colors-medium-brown-900: #1d1211 !default;
$emu-common-colors-dark-brown-100: #dbd4d4 !default;
$emu-common-colors-dark-brown-200: #b6a9a9 !default;
$emu-common-colors-dark-brown-300: #927d7e !default;
$emu-common-colors-dark-brown-400: #6d5253 !default;
$emu-common-colors-dark-brown-500: #492728 !default;
$emu-common-colors-dark-brown-600: #3a1f20 !default;
$emu-common-colors-dark-brown-700: #2c1718 !default;
$emu-common-colors-dark-brown-800: #1d1010 !default;
$emu-common-colors-dark-brown-900: #0f0808 !default;
$emu-common-colors-light-grey-100: #fefdfd !default;
$emu-common-colors-light-grey-200: #fdfbfb !default;
$emu-common-colors-light-grey-300: #fcfaf9 !default;
$emu-common-colors-light-grey-400: #fbf8f7 !default;
$emu-common-colors-light-grey-500: #faf6f5 !default;
$emu-common-colors-light-grey-600: #c8c5c4 !default;
$emu-common-colors-light-grey-700: #969493 !default;
$emu-common-colors-light-grey-800: #646262 !default;
$emu-common-colors-light-grey-900: #323131 !default;
$emu-common-colors-medium-grey-100: #f1f0f0 !default;
$emu-common-colors-medium-grey-200: #e3e1e1 !default;
$emu-common-colors-medium-grey-300: #d5d2d2 !default;
$emu-common-colors-medium-grey-400: #c7c3c3 !default;
$emu-common-colors-medium-grey-500: #b9b4b4 !default;
$emu-common-colors-medium-grey-600: #949090 !default;
$emu-common-colors-medium-grey-700: #6f6c6c !default;
$emu-common-colors-medium-grey-800: #4a4848 !default;
$emu-common-colors-medium-grey-900: #252424 !default;
$emu-common-colors-dark-grey-100: #dbdbdc !default;
$emu-common-colors-dark-grey-200: #b7b7b8 !default;
$emu-common-colors-dark-grey-300: #939495 !default;
$emu-common-colors-dark-grey-400: #6f7071 !default;
$emu-common-colors-dark-grey-500: #6f7071 !default;
$emu-common-colors-dark-grey-600: #3c3d3e !default;
$emu-common-colors-dark-grey-700: #2d2e2f !default;
$emu-common-colors-dark-grey-800: #1e1e1f !default;
$emu-common-colors-dark-grey-900: #0f0f10 !default;
$emu-common-colors-blush-100: #f8f3f5 !default;
$emu-common-colors-blush-200: #f1e6eb !default;
$emu-common-colors-blush-300: #ebdae0 !default;
$emu-common-colors-blush-400: #e4cdd6 !default;
$emu-common-colors-blush-500: #DDC1CC !default;
$emu-common-colors-blush-600: #b19aa3 !default;
$emu-common-colors-blush-700: #85747a !default;
$emu-common-colors-blush-800: #584d52 !default;
$emu-common-colors-blush-900: #2c2729 !default;
$emu-common-colors-abbvie-blue-100: #cdd2db !default;
$emu-common-colors-abbvie-blue-200: #9ca5b6 !default;
$emu-common-colors-abbvie-blue-300: #6a7792 !default;
$emu-common-colors-abbvie-blue-400: #394a6d !default;
$emu-common-colors-abbvie-blue-500: #071D49 !default;
$emu-common-colors-abbvie-blue-600: #06173a !default;
$emu-common-colors-abbvie-blue-700: #04112c !default;
$emu-common-colors-abbvie-blue-800: #030c1d !default;
$emu-common-colors-abbvie-blue-900: #01060f !default;
$emu-common-colors-purple-100: #D9D8E9 !default;
$emu-common-colors-purple-200: #9577BF !default;
$emu-common-colors-purple-300: #2E1844 !default;
$emu-common-colors-purple-400: #160C1F !default;
$emu-common-colors-purple-500: #2E1844 !default;
$emu-common-colors-purple-600: #2E1844 !default;
$emu-common-colors-gold-100: #F1EDE9 !default;
$emu-common-colors-gold-200: #E8E3DD !default;
$emu-common-colors-gold-300: #A08A5F !default;
$emu-common-colors-linen-50: #FFFFFF !default;
$emu-common-colors-linen-100: #FFFFFF !default;
$emu-common-colors-linen-200: #FDFCFC !default;
$emu-common-colors-linen-300: #F9F7F6 !default;
$emu-common-colors-linen-400: #F5F2EF !default;
$emu-common-colors-linen-500: #F1EDE9 !default;
$emu-common-colors-linen-600: #CBBDAE !default;
$emu-common-colors-linen-700: #A88F76 !default;
$emu-common-colors-linen-800: #735E49 !default;
$emu-common-colors-linen-900: #3B3026 !default;
$emu-common-colors-linen-950: #1C1712 !default;
$emu-common-colors-new-dawn-50: #FFFFFF !default;
$emu-common-colors-new-dawn-100: #FFFFFF !default;
$emu-common-colors-new-dawn-200: #F6F5F9 !default;
$emu-common-colors-new-dawn-300: #ECEBF4 !default;
$emu-common-colors-new-dawn-400: #E3E2EE !default;
$emu-common-colors-new-dawn-500: #D9D8E9 !default;
$emu-common-colors-new-dawn-600: #A09DC8 !default;
$emu-common-colors-new-dawn-700: #6A66A9 !default;
$emu-common-colors-new-dawn-800: #444072 !default;
$emu-common-colors-new-dawn-900: #23213B !default;
$emu-common-colors-new-dawn-950: #11111D !default;
$emu-common-colors-midnight-50: #F2ECF8 !default;
$emu-common-colors-midnight-100: #DBCAEC !default;
$emu-common-colors-midnight-200: #B08AD6 !default;
$emu-common-colors-midnight-300: #854ABF !default;
$emu-common-colors-midnight-400: #592F84 !default;
$emu-common-colors-midnight-500: #2E1844 !default;
$emu-common-colors-midnight-600: #241335 !default;
$emu-common-colors-midnight-700: #1C0F29 !default;
$emu-common-colors-midnight-800: #12091A !default;
$emu-common-colors-midnight-900: #0A050F !default;
$emu-common-colors-midnight-950: #050308 !default;
$emu-common-colors-new-dusk-50: #FCFCFD !default;
$emu-common-colors-new-dusk-100: #F1EEF7 !default;
$emu-common-colors-new-dusk-200: #D9CEE8 !default;
$emu-common-colors-new-dusk-300: #C4B3DB !default;
$emu-common-colors-new-dusk-400: #AB93CC !default;
$emu-common-colors-new-dusk-500: #9577BF !default;
$emu-common-colors-new-dusk-600: #7550AA !default;
$emu-common-colors-new-dusk-700: #563B7D !default;
$emu-common-colors-new-dusk-800: #3A2753 !default;
$emu-common-colors-new-dusk-900: #1D142A !default;
$emu-common-colors-new-dusk-950: #0E0A15 !default;
$emu-common-colors-new-fog-50: #FAFAFA !default;
$emu-common-colors-new-fog-100: #EDEDEE !default;
$emu-common-colors-new-fog-200: #D3D3D5 !default;
$emu-common-colors-new-fog-300: #B7B6B9 !default;
$emu-common-colors-new-fog-400: #9D9CA0 !default;
$emu-common-colors-new-fog-500: #838187 !default;
$emu-common-colors-new-fog-600: #68666B !default;
$emu-common-colors-new-fog-700: #4E4D51 !default;
$emu-common-colors-new-fog-800: #353437 !default;
$emu-common-colors-new-fog-900: #19191A !default;
$emu-common-colors-new-fog-950: #0D0C0D !default;
$emu-common-colors-shade-50: #F2F2F2 !default;
$emu-common-colors-shade-100: #E0E0E1 !default;
$emu-common-colors-shade-200: #B8B7B9 !default;
$emu-common-colors-shade-300: #919093 !default;
$emu-common-colors-shade-400: #69676A !default;
$emu-common-colors-shade-500: #414042 !default;
$emu-common-colors-shade-600: #333234 !default;
$emu-common-colors-shade-700: #262627 !default;
$emu-common-colors-shade-800: #1A191A !default;
$emu-common-colors-shade-900: #0D0D0D !default;
$emu-common-colors-shade-950: #080808 !default;
$emu-common-colors-valentines-50: #FFEBF3 !default;
$emu-common-colors-valentines-100: #FFD1E3 !default;
$emu-common-colors-valentines-200: #FFA8CB !default;
$emu-common-colors-valentines-300: #FF7AAF !default;
$emu-common-colors-valentines-400: #FF5297 !default;
$emu-common-colors-valentines-500: #FF257E !default;
$emu-common-colors-valentines-600: #EB005E !default;
$emu-common-colors-valentines-700: #AD0045 !default;
$emu-common-colors-valentines-800: #75002F !default;
$emu-common-colors-valentines-900: #380016 !default;
$emu-common-colors-valentines-950: #1F000C !default;
$emu-common-font-families-sans: Arial !default;
$emu-common-font-families-serif: Times New Roman !default;
$emu-common-font-families-mono: Courier !default;
$emu-common-font-weight-bold: 700 !default;
$emu-common-font-weight-light: 300 !default;
$emu-common-font-weight-black: 900 !default;
$emu-common-font-weight-regular: 400 !default;
$emu-common-font-sizes-narrow-medium: 12px !default;
$emu-common-font-sizes-narrow-large: 16.97px !default;
$emu-common-font-sizes-narrow-xl: 23.99px !default;
$emu-common-font-sizes-narrow-xxl: 33.93px !default;
$emu-common-font-sizes-narrow-xxxl: 47.97px !default;
$emu-common-font-sizes-wide-medium: 16px !default;
$emu-common-font-sizes-wide-large: 22.62px !default;
$emu-common-font-sizes-wide-xl: 31.99px !default;
$emu-common-font-sizes-wide-xxl: 45.23px !default;
$emu-common-font-sizes-wide-xxxl: 63.96px !default;
$emu-common-line-heights-narrow-large: 18px !default;
$emu-common-line-heights-narrow-medium: 18px !default;
$emu-common-line-heights-narrow-xl: 36px !default;
$emu-common-line-heights-narrow-xxl: 36px !default;
$emu-common-line-heights-narrow-xxxl: 54px !default;
$emu-common-line-heights-wide-large: 24px !default;
$emu-common-line-heights-wide-medium: 24px !default;
$emu-common-line-heights-wide-xl: 48px !default;
$emu-common-line-heights-wide-xxl: 48px !default;
$emu-common-line-heights-wide-xxxl: 72px !default;
$emu-common-border-radius-xxs: 2px !default;
$emu-common-border-radius-xs: 4px !default;
$emu-common-border-radius-small: 8px !default;
$emu-common-border-radius-medium: 16px !default;
$emu-common-border-radius-large: 32px !default;
$emu-common-border-radius-none: 0px !default;
$emu-common-border-width-thin: 1px !default;
$emu-common-border-width-medium: 2px !default;
$emu-common-border-width-thick: 4px !default;
$emu-common-border-width-none: 0px !default;
$emu-common-outline-width-thin: thin !default;
$emu-common-outline-width-medium: medium !default;
$emu-common-outline-width-thick: thick !default;
$emu-common-outline-width-unset: unset !default;
$emu-common-sizing-none: 0px !default;
$emu-common-sizing-xxs: 1px !default;
$emu-common-sizing-xs: 8px !default;
$emu-common-sizing-small: 16px !default;
$emu-common-sizing-medium: 32px !default;
$emu-common-sizing-large: 64px !default;
$emu-common-sizing-xl: 128px !default;
$emu-common-sizing-xxl: 256px !default;
$emu-common-sizing-container: 1520px !default;
$emu-common-other-time-transition-short: 200ms !default;
$emu-common-other-time-transition-base: 400ms !default;
$emu-common-other-time-transition-long: 600ms !default;
$emu-common-other-time-transition-xl: 1000ms !default;
$emu-common-other-time-duration-instant: 400ms !default;
$emu-common-other-time-duration-short: 2000ms !default;
$emu-common-other-time-duration-base: 4000ms !default;
$emu-common-other-time-duration-long: 6000ms !default;
$emu-common-other-time-delay-none: 0ms !default;
$emu-common-other-time-delay-short: 50ms !default;
$emu-common-other-time-delay-base: 100ms !default;
$emu-common-other-time-delay-long: 200ms !default;
$emu-common-other-z-index-cookie-banner: 700 !default;
$emu-common-other-z-index-modal: 600 !default;
$emu-common-other-z-index-header: 500 !default;
$emu-common-other-z-index-isi: 400 !default;
$emu-common-other-z-index-overlay: 300 !default;
$emu-common-other-z-index-layer: 100 !default;
$emu-common-other-z-index-base: 0 !default;
$emu-common-other-z-index-behind: -1 !default;
$emu-common-box-shadow-soft-light: 0 0 6 0 #827F85 !default;

$common: (
  'common': (
    'spacing': (
      'xs': $emu-common-spacing-xs,
      'xxs': $emu-common-spacing-xxs,
      'small': $emu-common-spacing-small,
      'medium': $emu-common-spacing-medium,
      'large': $emu-common-spacing-large,
      'xl': $emu-common-spacing-xl,
      'none': $emu-common-spacing-none,
      'unset': $emu-common-spacing-unset
    ),
    'colors': (
      'white': $emu-common-colors-white,
      'black': $emu-common-colors-black,
      'transparent': $emu-common-colors-transparent,
      'red': (
        '100': $emu-common-colors-red-100,
        '200': $emu-common-colors-red-200,
        '300': $emu-common-colors-red-300,
        '400': $emu-common-colors-red-400,
        '500': $emu-common-colors-red-500,
        '600': $emu-common-colors-red-600,
        '700': $emu-common-colors-red-700,
        '800': $emu-common-colors-red-800,
        '900': $emu-common-colors-red-900
      ),
      'grey': (
        '50': $emu-common-colors-grey-50,
        '100': $emu-common-colors-grey-100,
        '200': $emu-common-colors-grey-200,
        '300': $emu-common-colors-grey-300,
        '400': $emu-common-colors-grey-400,
        '500': $emu-common-colors-grey-500,
        '600': $emu-common-colors-grey-600,
        '700': $emu-common-colors-grey-700,
        '800': $emu-common-colors-grey-800,
        '900': $emu-common-colors-grey-900,
        '950': $emu-common-colors-grey-950
      ),
      'teal': (
        '100': $emu-common-colors-teal-100,
        '200': $emu-common-colors-teal-200,
        '300': $emu-common-colors-teal-300,
        '400': $emu-common-colors-teal-400,
        '500': $emu-common-colors-teal-500,
        '600': $emu-common-colors-teal-600,
        '700': $emu-common-colors-teal-700,
        '800': $emu-common-colors-teal-800,
        '900': $emu-common-colors-teal-900
      ),
      'beige': (
        '100': $emu-common-colors-beige-100,
        '200': $emu-common-colors-beige-200,
        '300': $emu-common-colors-beige-300,
        '400': $emu-common-colors-beige-400,
        '500': $emu-common-colors-beige-500,
        '600': $emu-common-colors-beige-600,
        '700': $emu-common-colors-beige-700,
        '800': $emu-common-colors-beige-800,
        '900': $emu-common-colors-beige-900
      ),
      'lightBrown': (
        '100': $emu-common-colors-light-brown-100,
        '200': $emu-common-colors-light-brown-200,
        '300': $emu-common-colors-light-brown-300,
        '400': $emu-common-colors-light-brown-400,
        '500': $emu-common-colors-light-brown-500,
        '600': $emu-common-colors-light-brown-600,
        '700': $emu-common-colors-light-brown-700,
        '800': $emu-common-colors-light-brown-800,
        '900': $emu-common-colors-light-brown-900
      ),
      'mediumBrown': (
        '100': $emu-common-colors-medium-brown-100,
        '200': $emu-common-colors-medium-brown-200,
        '300': $emu-common-colors-medium-brown-300,
        '400': $emu-common-colors-medium-brown-400,
        '500': $emu-common-colors-medium-brown-500,
        '600': $emu-common-colors-medium-brown-600,
        '700': $emu-common-colors-medium-brown-700,
        '800': $emu-common-colors-medium-brown-800,
        '900': $emu-common-colors-medium-brown-900
      ),
      'darkBrown': (
        '100': $emu-common-colors-dark-brown-100,
        '200': $emu-common-colors-dark-brown-200,
        '300': $emu-common-colors-dark-brown-300,
        '400': $emu-common-colors-dark-brown-400,
        '500': $emu-common-colors-dark-brown-500,
        '600': $emu-common-colors-dark-brown-600,
        '700': $emu-common-colors-dark-brown-700,
        '800': $emu-common-colors-dark-brown-800,
        '900': $emu-common-colors-dark-brown-900
      ),
      'lightGrey': (
        '100': $emu-common-colors-light-grey-100,
        '200': $emu-common-colors-light-grey-200,
        '300': $emu-common-colors-light-grey-300,
        '400': $emu-common-colors-light-grey-400,
        '500': $emu-common-colors-light-grey-500,
        '600': $emu-common-colors-light-grey-600,
        '700': $emu-common-colors-light-grey-700,
        '800': $emu-common-colors-light-grey-800,
        '900': $emu-common-colors-light-grey-900
      ),
      'mediumGrey': (
        '100': $emu-common-colors-medium-grey-100,
        '200': $emu-common-colors-medium-grey-200,
        '300': $emu-common-colors-medium-grey-300,
        '400': $emu-common-colors-medium-grey-400,
        '500': $emu-common-colors-medium-grey-500,
        '600': $emu-common-colors-medium-grey-600,
        '700': $emu-common-colors-medium-grey-700,
        '800': $emu-common-colors-medium-grey-800,
        '900': $emu-common-colors-medium-grey-900
      ),
      'darkGrey': (
        '100': $emu-common-colors-dark-grey-100,
        '200': $emu-common-colors-dark-grey-200,
        '300': $emu-common-colors-dark-grey-300,
        '400': $emu-common-colors-dark-grey-400,
        '500': $emu-common-colors-dark-grey-500,
        '600': $emu-common-colors-dark-grey-600,
        '700': $emu-common-colors-dark-grey-700,
        '800': $emu-common-colors-dark-grey-800,
        '900': $emu-common-colors-dark-grey-900
      ),
      'blush': (
        '100': $emu-common-colors-blush-100,
        '200': $emu-common-colors-blush-200,
        '300': $emu-common-colors-blush-300,
        '400': $emu-common-colors-blush-400,
        '500': $emu-common-colors-blush-500,
        '600': $emu-common-colors-blush-600,
        '700': $emu-common-colors-blush-700,
        '800': $emu-common-colors-blush-800,
        '900': $emu-common-colors-blush-900
      ),
      'abbvieBlue': (
        '100': $emu-common-colors-abbvie-blue-100,
        '200': $emu-common-colors-abbvie-blue-200,
        '300': $emu-common-colors-abbvie-blue-300,
        '400': $emu-common-colors-abbvie-blue-400,
        '500': $emu-common-colors-abbvie-blue-500,
        '600': $emu-common-colors-abbvie-blue-600,
        '700': $emu-common-colors-abbvie-blue-700,
        '800': $emu-common-colors-abbvie-blue-800,
        '900': $emu-common-colors-abbvie-blue-900
      ),
      'purple': (
        '100': $emu-common-colors-purple-100,
        '200': $emu-common-colors-purple-200,
        '300': $emu-common-colors-purple-300,
        '400': $emu-common-colors-purple-400,
        '500': $emu-common-colors-purple-500,
        '600': $emu-common-colors-purple-600
      ),
      'gold': (
        '100': $emu-common-colors-gold-100,
        '200': $emu-common-colors-gold-200,
        '300': $emu-common-colors-gold-300
      ),
      'linen': (
        '50': $emu-common-colors-linen-50,
        '100': $emu-common-colors-linen-100,
        '200': $emu-common-colors-linen-200,
        '300': $emu-common-colors-linen-300,
        '400': $emu-common-colors-linen-400,
        '500': $emu-common-colors-linen-500,
        '600': $emu-common-colors-linen-600,
        '700': $emu-common-colors-linen-700,
        '800': $emu-common-colors-linen-800,
        '900': $emu-common-colors-linen-900,
        '950': $emu-common-colors-linen-950
      ),
      'new-dawn': (
        '50': $emu-common-colors-new-dawn-50,
        '100': $emu-common-colors-new-dawn-100,
        '200': $emu-common-colors-new-dawn-200,
        '300': $emu-common-colors-new-dawn-300,
        '400': $emu-common-colors-new-dawn-400,
        '500': $emu-common-colors-new-dawn-500,
        '600': $emu-common-colors-new-dawn-600,
        '700': $emu-common-colors-new-dawn-700,
        '800': $emu-common-colors-new-dawn-800,
        '900': $emu-common-colors-new-dawn-900,
        '950': $emu-common-colors-new-dawn-950
      ),
      'midnight': (
        '50': $emu-common-colors-midnight-50,
        '100': $emu-common-colors-midnight-100,
        '200': $emu-common-colors-midnight-200,
        '300': $emu-common-colors-midnight-300,
        '400': $emu-common-colors-midnight-400,
        '500': $emu-common-colors-midnight-500,
        '600': $emu-common-colors-midnight-600,
        '700': $emu-common-colors-midnight-700,
        '800': $emu-common-colors-midnight-800,
        '900': $emu-common-colors-midnight-900,
        '950': $emu-common-colors-midnight-950
      ),
      'new-dusk': (
        '50': $emu-common-colors-new-dusk-50,
        '100': $emu-common-colors-new-dusk-100,
        '200': $emu-common-colors-new-dusk-200,
        '300': $emu-common-colors-new-dusk-300,
        '400': $emu-common-colors-new-dusk-400,
        '500': $emu-common-colors-new-dusk-500,
        '600': $emu-common-colors-new-dusk-600,
        '700': $emu-common-colors-new-dusk-700,
        '800': $emu-common-colors-new-dusk-800,
        '900': $emu-common-colors-new-dusk-900,
        '950': $emu-common-colors-new-dusk-950
      ),
      'new-fog': (
        '50': $emu-common-colors-new-fog-50,
        '100': $emu-common-colors-new-fog-100,
        '200': $emu-common-colors-new-fog-200,
        '300': $emu-common-colors-new-fog-300,
        '400': $emu-common-colors-new-fog-400,
        '500': $emu-common-colors-new-fog-500,
        '600': $emu-common-colors-new-fog-600,
        '700': $emu-common-colors-new-fog-700,
        '800': $emu-common-colors-new-fog-800,
        '900': $emu-common-colors-new-fog-900,
        '950': $emu-common-colors-new-fog-950
      ),
      'shade': (
        '50': $emu-common-colors-shade-50,
        '100': $emu-common-colors-shade-100,
        '200': $emu-common-colors-shade-200,
        '300': $emu-common-colors-shade-300,
        '400': $emu-common-colors-shade-400,
        '500': $emu-common-colors-shade-500,
        '600': $emu-common-colors-shade-600,
        '700': $emu-common-colors-shade-700,
        '800': $emu-common-colors-shade-800,
        '900': $emu-common-colors-shade-900,
        '950': $emu-common-colors-shade-950
      ),
      'valentines': (
        '50': $emu-common-colors-valentines-50,
        '100': $emu-common-colors-valentines-100,
        '200': $emu-common-colors-valentines-200,
        '300': $emu-common-colors-valentines-300,
        '400': $emu-common-colors-valentines-400,
        '500': $emu-common-colors-valentines-500,
        '600': $emu-common-colors-valentines-600,
        '700': $emu-common-colors-valentines-700,
        '800': $emu-common-colors-valentines-800,
        '900': $emu-common-colors-valentines-900,
        '950': $emu-common-colors-valentines-950
      )
    ),
    'fontFamilies': (
      'sans': $emu-common-font-families-sans,
      'serif': $emu-common-font-families-serif,
      'mono': $emu-common-font-families-mono
    ),
    'fontWeight': (
      'bold': $emu-common-font-weight-bold,
      'light': $emu-common-font-weight-light,
      'black': $emu-common-font-weight-black,
      'regular': $emu-common-font-weight-regular
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-common-font-sizes-narrow-medium,
        'large': $emu-common-font-sizes-narrow-large,
        'xl': $emu-common-font-sizes-narrow-xl,
        'xxl': $emu-common-font-sizes-narrow-xxl,
        'xxxl': $emu-common-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-common-font-sizes-wide-medium,
        'large': $emu-common-font-sizes-wide-large,
        'xl': $emu-common-font-sizes-wide-xl,
        'xxl': $emu-common-font-sizes-wide-xxl,
        'xxxl': $emu-common-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-common-line-heights-narrow-large,
        'medium': $emu-common-line-heights-narrow-medium,
        'xl': $emu-common-line-heights-narrow-xl,
        'xxl': $emu-common-line-heights-narrow-xxl,
        'xxxl': $emu-common-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-common-line-heights-wide-large,
        'medium': $emu-common-line-heights-wide-medium,
        'xl': $emu-common-line-heights-wide-xl,
        'xxl': $emu-common-line-heights-wide-xxl,
        'xxxl': $emu-common-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xxs': $emu-common-border-radius-xxs,
      'xs': $emu-common-border-radius-xs,
      'small': $emu-common-border-radius-small,
      'medium': $emu-common-border-radius-medium,
      'large': $emu-common-border-radius-large,
      'none': $emu-common-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-common-border-width-thin,
      'medium': $emu-common-border-width-medium,
      'thick': $emu-common-border-width-thick,
      'none': $emu-common-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-common-outline-width-thin,
      'medium': $emu-common-outline-width-medium,
      'thick': $emu-common-outline-width-thick,
      'unset': $emu-common-outline-width-unset
    ),
    'sizing': (
      'none': $emu-common-sizing-none,
      'xxs': $emu-common-sizing-xxs,
      'xs': $emu-common-sizing-xs,
      'small': $emu-common-sizing-small,
      'medium': $emu-common-sizing-medium,
      'large': $emu-common-sizing-large,
      'xl': $emu-common-sizing-xl,
      'xxl': $emu-common-sizing-xxl,
      'container': $emu-common-sizing-container
    ),
    'other': (
      'time': (
        'transition': (
          'short': $emu-common-other-time-transition-short,
          'base': $emu-common-other-time-transition-base,
          'long': $emu-common-other-time-transition-long,
          'xl': $emu-common-other-time-transition-xl
        ),
        'duration': (
          'instant': $emu-common-other-time-duration-instant,
          'short': $emu-common-other-time-duration-short,
          'base': $emu-common-other-time-duration-base,
          'long': $emu-common-other-time-duration-long
        ),
        'delay': (
          'none': $emu-common-other-time-delay-none,
          'short': $emu-common-other-time-delay-short,
          'base': $emu-common-other-time-delay-base,
          'long': $emu-common-other-time-delay-long
        )
      ),
      'zIndex': (
        'cookieBanner': $emu-common-other-z-index-cookie-banner,
        'modal': $emu-common-other-z-index-modal,
        'header': $emu-common-other-z-index-header,
        'isi': $emu-common-other-z-index-isi,
        'overlay': $emu-common-other-z-index-overlay,
        'layer': $emu-common-other-z-index-layer,
        'base': $emu-common-other-z-index-base,
        'behind': $emu-common-other-z-index-behind
      )
    ),
    'boxShadow': (
      'soft': (
        'light': $emu-common-box-shadow-soft-light
      )
    )
  )
);
