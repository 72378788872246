[data-component='modal'] {
  .modal-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-content {
    width: 100%; // as per figma
    padding-top: 43px;
    padding-bottom: 44px;
    padding-right: 20px;
    padding-left: 24px;
    border-radius: var(--emu-common-border-radius-medium);
    margin-top: 40px; // as per figma
    margin-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-top: 47px;
      padding-right: 60px;
      padding-left: 70px;
      margin-top: 121px; // as per figma
    }

    .cmp-title__text {
      margin-bottom: 17px;

      @include mq('large') {
        margin-bottom: 23px;
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }
    }

    ul {
      padding-left: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: 17px;
      }

      li {
        margin-bottom: 10px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }
    }

    .references {
      margin-top: 10px;
      margin-bottom: var(--emu-common-sizing-none);
      padding: var(--emu-common-sizing-none);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-medium);
      }
    }

    .close {
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/close.png');
      background-size: cover;
      right: 20px;
      top: 18px;

      @include mq('large') {
        top: 20px;
      }

      &,
      &:hover {
        opacity: 1;
      }

      span {
        display: none;
      }
    }
  }
}

body.js-modal-opened {
  overflow: hidden;

  [data-component='container-isi-v2'].isi-scrollable .isi-container {
    z-index: var(--emu-common-other-z-index-modal);
  }
}
