.aaaem-container {
  margin: 0;
  padding: 0;
  background-color: transparent;   
}#aesthetics-brands-trigger {
  span {
    color: #FFF;
  }
}

.header {
  .emu-navigation__content-wrapper nav > ul > li > a:hover {
    background-color: transparent;
    opacity: 0.7;
  }
  &__menu-mobile-dropdown-bottom-content-social-links {
    background-color: transparent;
    a {
      display: flex;
    }
  }
  &__content,
  &__menu {
    background-color: transparent;
    .container,
    .aaaem-container {
      background-color: transparent;    
    }
  }
  &__desktop-image-wrapper {
    >.image:first-of-type {
      display: none;
    }
  } 
  .emu-navigation {
    background-color: transparent;
    &__item {
      a {
        border: none; 
      }
    }
  }
}

#valentines-day {
  div.header {
    .header {
      &__menu-mobile-dropdown-bottom-content {
        background-color: var(--emu-semantic-colors-tertiary-light);
      }
      &__menu-mobile-dropdown {
        >.button {
          .emu-button {
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
          }
          span {
            font-size: 16px;
          }
        }
      }
      &__menu-mobile {
        >.image:first-of-type {
          display: none;
        }
        >.image:nth-of-type(2) {
          display: block;
          .emu-image {
            display: block;
          }
          @include mq('large') {
            display: none;
          }
        }
      }
    }
    header:not(.header--sticky) {
      .header {
        &__menu-mobile-trigger {
          span {
            background-color: var(--emu-common-colors-white);
            &::before,
            &::after {
              background-color: var(--emu-common-colors-white);
            }
          }
        }
        &__menu-mobile-dropdown {
          >.button {
            .emu-button {
              background-color: var(--emu-common-colors-white);
              border-color: var(--emu-common-colors-white);
            }
            span {
              color: var(--emu-common-colors-midnight-900);
            }
            &:hover {
              opacity: 0.7;
            }
            &:focus,
            &:active {
              opacity: 1;
              .emu-button {
                border-color: var(--emu-common-colors-new-dusk-300);
              }
            }
          }
        }
      }
    }
    .header--sticky {
      &.header {
        .header {
          &__menu-mobile {
            >.image:first-of-type {
              display: block;
              .emu-image {
                display: block;
              }
              @include mq('large') {
                display: none;
              }
            }
            >.image:nth-of-type(2) {
              display: none;
            }
          }
          &__desktop-image-wrapper {
            .image:first-of-type {
              display: block;
              img {
                height: 80px;
              }
            }
            .image:nth-of-type(2) {
              display: none;
            }
          }
          &__menu {
            > * {
              background-color: var(--emu-common-colors-white);
            }
          }
          &__menu-mobile-dropdown {
            .header__menu-mobile-dropdown-btn {
              @include mq('large') {
                margin-top: 18px;
              }
            }
            .emu-navigation__content-wrapper {
              @include mq('large') {
                margin-top: 26px;
              }
            }
            > div.container {
                &:first-child {
                  @include mq('large') {
                    display: block;
                  }
                }
              }
          }
        }
        .emu-navigation {
          &__item {
            a {
              color: var(--emu-common-colors-black);
            }
          }
        }
      }
    }
  } 
  .page-hero {
    height: 503px;
    @include mq('large') {
      margin-top: 34px;
    }
    .title-lockup {
      text-align: left;
      @include mq('large') {
        text-align: center;
      }
      h2 {
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .cmp-teaser__description {
      p {
        color: var(--emu-semantic-colors-secondary-light);
      }
    }
  }
  .aaaem-teaser {
    &__pretitle {
      font: var(--emu-component-ingredients-title-h3-typography-narrow);
      @include mq('large') {
        font: var(--emu-component-ingredients-title-h3-typography-wide);
      }
    }
    &__title {
      font: var(--emu-component-ingredients-title-h1-typography-narrow);
      @include mq('large') {
        font: var(--emu-component-ingredients-title-h1-typography-wide);
      }
    }
    &__subtitle {
      font: var(--emu-component-ingredients-title-h2-typography-narrow);
      @include mq('large') {
        font: var(--emu-component-ingredients-title-h2-typography-wide);
      }
    }
    .cmp-teaser__description {
      p {
        font: var(--emu-component-ingredients-text-typography-narrow);
        @include mq('large') {
          font: var(--emu-component-ingredients-text-typography-wide);
        }
      }
    }
  }
  .h2-secondary-varient {
    h2 {
      color: var(--emu-semantic-colors-primary-light);
    }
  }
  .aaaem-accordion {
    max-width: 1229px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding-left: 40px;
    padding-right: 20px;
    &__panel {
      background-color: var(--emu-component-lists-accordion-item-header-color-background-light);
    }
  }
}


.even-columns {
    display: flex;
    flex-direction: column;
    @include mq('large') {
      flex-direction: row;
      max-width: 1229px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      padding-left: 40px;
      padding-right: 20px;
    }
    .group-1 {
        
    }
  .gift-card-teaser {
    margin-bottom: 40px;
    margin-top: 40px;
    position: relative;
    @include mq('large') {
      margin-bottom: 0;
      margin-top: 0;
    }
    &.aaaem-teaser {
      .cmp-teaser {
        &__action-link-text {
          display: block;
        }
      }
      &__action-container {
        display: inline;
      }
    }
    .teaser-image-container {
      .cmp-teaser__image--mobile {
        .emu-image {
          width: max-content;
        }
        img {
          height: 208px;
          width: auto;
        }
      }
      .cmp-teaser {
        &__image {
          &--desktop {
            .emu-image {
              width: max-content;
            }
            img {
              height: 290px;
              width: auto;
            }
          }
          &--mobile {
            .emu-image {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
    .content-container {
      position: absolute;
      padding: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}

.cmp-link__screen-reader-only {
  display: inline-block;
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 1px;
}
