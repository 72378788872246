.emu-navigation {
  margin-left: 20px;
  margin-right: 20px;

  @include mq('large') {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }

  &__content-wrapper {
    position: relative;
    margin-top: 10px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    nav {
      ul {
        @include mq('medium') {
          flex-direction: column;
        }

        @include mq('large') {
          flex-direction: row;
        }

        > li:last-child {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }

        li {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-large);
          }

          @include mq('x-large') {
            margin-right: 40px;
          }

          &.emu-navigation__item--active {
            background-color: var(--emu-common-colors-purple-200);
            border-radius: var(--emu-common-border-radius-xs);

            @include mq('large') {
              background-color: var(--emu-common-colors-transparent);
              position: relative;

              &::after {
                content: '';
                position: absolute;
                bottom: -10;
                left: 0;
                width: 100%;
                height: 4px;
                border-radius: var(--emu-common-border-radius-xxs);
                background-color: var(--emu-common-colors-purple-200);
              }
            }

            a,
            a:hover,
            a:focus,
            a:active {
              color: var(--emu-common-colors-white);

              @include mq('large') {
                color: var(--emu-common-colors-purple-300);
              }

              &::after {
                border-top-color: var(--emu-common-colors-white);
                border-right-color: var(--emu-common-colors-white);
              }
            }
          }

          a {
            position: relative;
            font-size: 18px;
            line-height: 24px;

            @include mq('large') {
              padding-top: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              padding-left: var(--emu-common-spacing-none);
              font-size: var(--emu-common-font-sizes-wide-medium);
              font-weight: var(--emu-common-font-weight-regular);
              line-height: normal;

              &::after {
                display: none;
              }
            }

            sup {
              font-size: inherit;
              line-height: 0px;
              position: relative;
              top: 7px;

              @include mq('large') {
                top: 6px;
              }
            }

            &::after {
              content: '';
              position: absolute;
              right: 20px;
              top: 20px;
              width: 12px; //as per figma
              height: 12px; //as per figma
              border-top: var(--emu-common-border-width-medium) solid
                var(--emu-common-colors-black);
              border-right: var(--emu-common-border-width-medium) solid
                var(--emu-common-colors-black);
              transform: rotate(42deg);
            }
          }
        }
      }
    }
  }
}
