#the-look-of-three {
  .areas-hotspot {
    padding-bottom: 42px;

    @include mq('large') {
      padding-bottom: 63px;
    }

    &__disclaimer {
      p {
        margin-bottom: var(--emu-common-spacing-none);
        max-width: none;
      }
    }
  }

  .common-teaser {
    &__wrapper {
      padding-bottom: 20px;

      @include mq('large') {
        padding-bottom: 33px;
      }

      .aaaem-teaser {
        .content-container {
          > .content {
            @include mq('large') {
              padding-top: 129px;
              margin-bottom: 52px;
            }

            sup {
              line-height: 0px;
            }
          }
        }

        .cmp-teaser {
          &__description {
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
          }

          &__title {
            @include mq('large') {
              margin-bottom: 10px;
            }

            sup {
              @include mq('large') {
                position: relative;
                top: -2px;
              }

              &:first-child {
                font-weight: var(--emu-common-font-weight-light);
                position: initial;

                @include mq('large') {
                  font-size: var(--emu-semantic-font-sizes-wide-large);
                }
              }
            }
          }
        }
      }
    }

    &__disclaimer {
      p {
        @include mq('large') {
          width: 500px; // as per figma
        }
      }
    }
  }

  .tl3__patient-section,
  .tl3__testimonial-section {
    @include mq('large') {
      max-width: 950px; // to match the design.
    }
  }

  .tl3 {
    &__intro {
      padding-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include mq('large') {
        padding-top: 50px;
        padding-bottom: 45px;
        flex-direction: row;
        align-items: center;
        gap: 47px;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-secondary-dark-gray-300);
      }

      &-image {
        display: flex;
        justify-content: center;

        @include mq('large') {
          justify-content: left;
        }

        img {
          width: 140px; // as per figma

          @include mq('large') {
            width: auto;
          }
        }
      }

      &-wrapper {
        padding-bottom: 24px;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-secondary-dark-gray-300);

        @include mq('large') {
          border-bottom: none;
          padding-bottom: var(--emu-common-spacing-none);
          max-width: 844px; // as per figma
        }
      }

      &-title {
        margin-bottom: 20px;

        @include mq('large') {
          text-align: left;
        }
      }

      &-text {
        margin-bottom: 19px;

        p {
          text-align: center;

          @include mq('large') {
            text-align: left;
          }
        }
      }

      &-disclaimer {
        p {
          text-align: center;
          font-size: 14px;
          line-height: 21px;
          color: var(--emu-common-colors-purple-400);
          margin-left: -4px;
          margin-right: -4px;

          @include mq('large') {
            text-align: left;
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
            text-indent: -7px;
          }
        }
      }
    }

    &__patient {
      &-section {
        margin-top: 24px;
        margin-bottom: 43px;

        @include mq('large') {
          margin-top: 42px;
          margin-bottom: 72px;
        }

        .cmp-title__text,
        .aaaem-text {
          margin-bottom: 20px;
          br {
            display: none;

            @include mq('large') {
              display: block;
            }
          }
        }

        &-subtitle {
          @include mq('large') {
            margin-bottom: 40px; // intentional, the design shows 15, but it is more than thats
          }
        }
      }

      &-small-text.aaaem-text {
        font-size: 14px;
        line-height: var(--emu-semantic-font-sizes-narrow-large);
        margin-bottom: 21px;

        @include mq('large') {
          margin-bottom: 37px;
        }
      }

      &-list.aaaem-text {
        margin-top: 2px;

        @include mq('large') {
          margin-top: 29px;
        }
      }
    }

    &__filppable-card {
      &-wrapper {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
          justify-content: center;
          gap: 31px;
        }

        .flippablecard {
          margin-bottom: 26px;
        }

        .flippable-card {
          margin: var(--emu-common-spacing-none);

          &__wrapper {
            max-width: 296px; // as per the design.
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .flipped-card {
              height: 100%;

              > div.aaaem-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;
                padding-top: 85px;
                padding-right: 30px;
                padding-left: 30px;
                padding-bottom: 30px;
              }
            }

            .tl3__filppable-card-img-text-grid {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .aaaem-text {
                margin: var(--emu-common-spacing-none);
              }

              .aaaem-image {
                width: var(--emu-common-sizing-small); // As per the design.
                margin-left: var(--emu-common-spacing-xs);
                margin-top: 6px;
              }
            }
          }

          &__icon {
            position: absolute;
            top: 30px; // As per the design.
            left: 30px; // As per the design.
          }
        }
      }

      &-title {
        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          letter-spacing: -1px; // to match the design.
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            margin-bottom: 6px;
          }

          sup {
            font-weight: var(--emu-common-font-weight-light);
          }
        }
      }

      &-text.aaaem-text {
        margin-bottom: 14px;

        @include mq('large') {
          margin-bottom: 29px;
        }
      }
    }

    &__testimonial-section {
      text-align: center;
      margin-right: 20px;
      margin-bottom: 44px;
      margin-left: 20px;
      padding-top: 43px;
      padding-bottom: 50px;
      border-radius: var(--emu-common-border-radius-medium);
      background-color: #f8f7f8; // Used only here.

      @include mq('large') {
        margin-bottom: 74px;
        padding-top: 68px;
        padding-bottom: 74px;
        margin-left: auto;
        margin-right: auto;
      }

      .aaaem-text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -1px;
        margin-bottom: 24px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
          margin-bottom: 31px;
        }
      }

      .aaaem-button {
        padding-top: 10px;
        padding-right: var(--emu-common-spacing-large);
        padding-bottom: 10px;
        padding-left: var(--emu-common-spacing-large);

        .cmp-button__text {
          line-height: normal;
        }
      }
    }

    &__assess-patients-section {
      padding-bottom: 50px;

      @include mq('large') {
        padding-bottom: 72px;
      }

      .cmp-form-options__label {
        display: none;
      }

      .custom-select {
        margin-top: 29px;

        @include mq('large') {
          display: none;
        }
      }

      .aaaem-tabs__tablist {
        max-width: 690px;
        margin-left: auto;
        margin-right: auto;
      }

      .heading-primary {
        margin-bottom: 20px;
      }

      .aaaem-image img {
        margin: auto;
        width: 405px;
        max-width: 100%;
      }

      &-disclaimer {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 44px;

        @include mq('large') {
          margin-bottom: 66px;
          max-width: 467px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-inner {
        border-radius: 16px;
        padding-top: 32px;
        margin: auto;
        padding-bottom: 35px;
        padding-left: 20px;
        padding-right: 20px;
        background: linear-gradient(
          180deg,
          #e8e3dd 0%,
          rgba(187, 169, 205, 0.54) 27.2%,
          rgba(149, 119, 191, 0.37) 50.8%,
          rgba(185, 171, 213, 0.63) 75.73%,
          #d9d8e9 100%
        );

        @include mq('large') {
          padding-top: 62px;
          padding-bottom: 50px;
          padding-left: 72px;
          padding-right: 72px;
          max-width: 625px;
        }

        &-title {
          h3,
          h4,
          h5 {
            color: var(--emu-common-colors-purple-300);
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            font-weight: var(--emu-common-font-weight-bold);
            line-height: 32px;
            letter-spacing: -1px;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-xl);
              line-height: normal;
            }
          }
        }

        &-text {
          margin-top: 20px;
        }

        &-disclaimer p {
          color: var(--emu-common-colors-purple-400);
          text-align: center;
          font-size: 14px;
          margin-top: 21px;
          font-weight: var(--emu-common-font-weight-light);
          line-height: 21px;

          @include mq('large') {
            margin-top: 25px;
          }
        }
      }
    }

    &__educate-patients-section {
      padding-top: 44px;

      @include mq('large') {
        padding-top: 59px;
        width: 752px; // as per figma
      }

      .aaaem-accordion {
        max-width: 624px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          margin-top: 41px;
        }
      }

      &-text {
        @include mq('large') {
          margin-left: -12px;
          margin-right: -12px;
        }

        p {
          color: var(--emu-common-colors-purple-300);
          text-align: center;
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: var(--emu-semantic-font-sizes-wide-large);
          letter-spacing: -1px;
          margin-top: 20px;
        }
      }

      &-disclaimer {
        margin-top: 14px;

        p {
          font-size: 14px;
          line-height: 21px;
          text-indent: -4px;
          color: var(--emu-common-colors-purple-400);

          &:nth-child(2) {
            text-indent: -14px;
          }
        }
      }

      &-inner {
        .aaaem-text {
          @include mq('large') {
            margin-top: 12px;
          }

          a {
            text-decoration: none;
            color: var(--emu-common-colors-purple-200);
          }
        }

        .button {
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;

          .aaaem-button {
            padding-left: 22px;
            padding-right: 22px;
          }
        }
      }
    }

    &__content-with-img-section {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
      padding-bottom: 10px;

      @include mq('large') {
        text-align: left;
        flex-direction: row;
        padding-bottom: var(--emu-common-spacing-none);
        gap: 10px;

        > .container:first-child {
          flex: 1;
        }
      }

      &-text {
        margin-top: 20px;
        margin-bottom: 28px;

        @include mq('large') {
          margin-bottom: 37px;
        }
      }

      .image img {
        margin: auto;
        margin-bottom: 23px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      .heading-primary {
        text-align: center;

        @include mq('large') {
          text-align: left;
        }
      }
    }

    &__ref-section {
      margin-top: 33px;
    }

    &__gradient-section {
      margin-bottom: 44px;

      @include mq('large') {
        margin-top: 80px;
        margin-bottom: 105px;
      }

      .gradient-section {
        margin-bottom: 28px;

        @include mq('large') {
          margin-bottom: 33px;
        }

        &__main {
          padding-bottom: 21px;

          @include mq('large') {
            justify-content: space-between;
          }

          > .container {
            &:first-child {
              @include mq('large') {
                width: 373px;
              }
            }
          }
        }

        &__carousel {
          padding-top: 44px;

          @include mq('large') {
            padding-top: 46px;
          }

          &-slide-title {
            .cmp-title__text {
              line-height: 44px;
              letter-spacing: -3px;
              margin-bottom: var(--emu-common-spacing-medium);

              @include mq('large') {
                margin-bottom: 23px;
              }
            }
          }
        }
      }

      .references {
        text-indent: -8px;
        padding: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
    }
  }
}
