.aaaem-tabs {
  &__tablist {
    background-color: var(--emu-common-colors-purple-200);
    border-radius: 27px;
    position: relative;
    margin-top: 29px;
    --tab-width: 200px;
    --active-tab-position: 0;

    @include mq('large') {
      margin-top: 34px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      border-radius: 27px;
    }

    &::after {
      width: 100%;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-purple-200);
      pointer-events: none;
    }

    &::before {
      left: calc(var(--active-tab-position) * var(--tab-width));
      width: var(--tab-width);
      background-color: var(--emu-common-colors-white);
      z-index: var(--emu-common-other-z-index-base);
      transition: all 0.2s linear;
    }
  }

  &__tab {
    flex: 1;
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    a {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: var(--emu-common-font-weight-bold);
      line-height: 1.5;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
      transition: all 0.2s linear;

      &[aria-selected='true'] {
        transition-delay: 0.1s;
      }
    }
  }
}
