.embed {
  .plyr {
    &--paused,
    &--stopped {
      .plyr__controls {
        display: none;
      }
    }

    &__volume {
      width: auto;
      min-width: var(--emu-common-sizing-none);
    }

    &__control {
      &--overlaid {
        &,
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
