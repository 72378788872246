@font-face {
  font-family: 'Kaushan Script';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/KaushanScript/KaushanScript-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownStd-LightItalic.woff2') format('woff2');
}
