// custom styles for swiper
.swiper-carousel {
  .swiper-wrapper {
    // editor view styles
    @include aem-editor-view {
      display: block !important;
    }

    .swiper-slide {
      width: auto;
      overflow: hidden;
    }
  }

  &.swiper-carousel--disabled-in-mobile {
    .swiper-wrapper {
      @include mq('1023px', 'max-width') {
        flex-direction: column;
        gap: 22px;
        margin-bottom: 22px;
      }
    }
  }
}
