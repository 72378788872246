.custom-select {
  position: relative;

  .custom-select__main {
    &.ss-main {
      text-align: left;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: var(--emu-common-border-radius-xs);
      background-color: var(--emu-common-colors-light-grey-100);
      width: 100%;
      height: 50px;
      color: var(--emu-common-colors-purple-400);
      font-size: 16px;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-purple-200);
      gap: 10px;

      .ss-single {
        margin-left: var(--emu-common-spacing-none);
        text-align: left;
        overflow: hidden;
        display: block;
      }

      .ss-values {
        max-width: calc(100% - 22px);
      }

      .ss-arrow {
        margin: var(--emu-common-spacing-none);
      }

      &[aria-expanded='true'] {
        background-color: var(--emu-common-colors-purple-200);
        color: var(--emu-common-colors-white);

        .ss-arrow path {
          stroke: var(--emu-common-colors-white);
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.ss-content.custom-select__main {
  border-radius: var(--emu-common-border-radius-xs);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15); // from figma
  overflow: hidden;
  min-width: 100%;
  width: auto !important;
  top: calc(100% + 7px) !important;
  left: 0 !important;

  .ss-list {
    .ss-option {
      padding-top: 14px;
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 14px;
      padding-left: var(--emu-common-spacing-medium);
      justify-content: flex-start;
      font-weight: var(--emu-common-font-weight-regular);
      font-size: 16px;
      text-align: left;

      @include mq('medium') {
        white-space: nowrap;
      }

      &:hover,
      &.ss-highlighted,
      &:not(.ss-disabled).ss-selected {
        background-color: var(--emu-common-colors-purple-100);
        color: var(--emu-common-colors-purple-400);
      }

      &.ss-disabled {
        background-color: var(--emu-common-colors-white);
        color: var(--emu-common-colors-purple-400);
        opacity: 0.3;
      }
    }
  }
}
