[data-component='container-isi-v2'].isi-scrollable .isi-container {
  z-index: var(--emu-component-containers-isi-z-index);
}

[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      background-color: var(--emu-common-colors-purple-300);

      @include mq('large') {
        padding-top: var(--emu-common-spacing-small);
        padding-right: 80px;
        padding-left: 80px;
        padding-bottom: var(--emu-common-spacing-small);
      }
    }

    &-container {
      border: none;
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      sup {
        font-size: 9px;
        vertical-align: revert; // to match the design.
        position: relative;
        top: 0;
      }

      &.isi-container {
        .aaaem-isi-container__isi-content {
          height: 176px;

          @include mq('large') {
            height: 168px;
          }

          > div {
            padding-top: var(--emu-common-spacing-small);
            padding-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              padding-top: 27px;
              padding-bottom: 27px;
            }
          }

          p,
          ul {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              font-size: 14px;
              margin-bottom: 21px;
            }
          }

          .hcp-botox-isi {
            &__box-content {
              padding-top: var(--emu-common-spacing-small);
              margin-bottom: var(--emu-common-spacing-small);

              @include mq('large') {
                padding-top: 15px;
                padding-right: 21px;
                padding-left: 21px;
                margin-top: -7px;
                margin-right: -21px;
                margin-bottom: 15px;
                margin-left: -21px;
              }

              p {
                @include mq('large') {
                  margin-bottom: 15px;
                }

                &:first-child {
                  @include mq('large') {
                    margin-bottom: 5px;
                  }
                }
              }
            }

            &__links {
              p {
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }

          &.js-expand {
            height: var(--emu-component-containers-isi-open-content-size);

            @include mq('large') {
              height: max(31vh, 278px); // to match the design.
            }
          }
        }
      }

      &__banner-content {
        display: block !important; // to override the css display property being added via JS

        p {
          font-size: 16px;
          line-height: var(--emu-semantic-font-sizes-narrow-large);
          margin: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
        }
      }

      &__button-wrapper {
        position: relative;
        top: -9px; // to match the design.
        right: -14px;

        @include mq('large') {
          top: unset;
          right: unset;
          align-self: center;
        }

        button.plus-minus-icon {
          padding: var(--emu-common-spacing-none);

          &:active {
            outline: none;
          }

          .aaaem-button__icon {
            margin: var(--emu-common-spacing-none);

            &::before,
            &::after {
              background-color: var(--emu-common-colors-white);
            }
          }
        }
      }

      &__isi-content {
        > div:first-child {
          @include mq('large') {
            padding-top: 27px;
            padding-right: 80px;
            padding-bottom: 27px;
            padding-left: 80px;
          }
        }

        .aaaem-text {
          font-size: 18px;
          line-height: 24px;
          color: var(--emu-common-colors-purple-300);
          font-weight: var(--emu-semantic-font-weight-light);

          @include mq('large') {
            font-size: 14px;
            line-height: 21px;
          }

          p {
            margin-bottom: 24px;

            @include mq('large') {
              margin-bottom: 21px;
            }
          }
        }

        .hcp-botox-isi {
          &__box-content {
            border: 1px solid var(--emu-common-colors-black);
            margin-right: -10px;
            margin-bottom: 24px;
            margin-left: -10px;
            padding-top: 24px;
            padding-right: 10px;
            padding-left: 10px;
            text-align: justify;

            @include mq('large') {
              padding-top: 15px;
              padding-right: 24px;
              padding-left: 19px;
              margin-top: -7px;
              margin-right: -24px;
              margin-bottom: 15px;
              margin-left: -19px;
            }

            p {
              @include mq('large') {
                margin-bottom: 15px;
              }

              &:first-child {
                margin-bottom: 10px;

                @include mq('large') {
                  margin-bottom: 5px;
                }
              }
            }
          }

          &__list,
          &__links {
            p {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }

          &__list {
            margin-bottom: 24px;

            @include mq('large') {
              margin-bottom: 21px;
            }

            p:not(:first-child) {
              margin-left: 14px;
              text-indent: -13px;
            }
          }

          &__links {
            a {
              color: inherit;
              overflow-wrap: break-word;
            }
          }
        }
      }
    }
  }
}
