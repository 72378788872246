.injectionpoints {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 43px;
  padding-bottom: var(--emu-common-spacing-large);

  @include mq('large') {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 84px;
    padding-bottom: var(--emu-common-spacing-none);
  }

  &__title {
    margin-bottom: 25px;

    @include mq('large') {
      margin-bottom: 40px;
      padding-left: 54px;
      padding-right: 54px;
    }

    h1,
    h2,
    h3 {
      line-height: normal;
    }

    sup {
      line-height: 0;
      font-family: var(--emu-semantic-font-families-body);
      font-weight: var(--emu-common-font-weight-light);
      font-size: 65%;
      vertical-align: super;
      position: relative;
      top: 3px;
      left: 2px;

      @include mq('large') {
        font-size: 55%;
      }
    }
  }

  &__upper-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    @include mq('large') {
      gap: 30px;
    }

    @include mq('x-large') {
      flex-direction: row;
    }

    @include mq('xx-large') {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__bottom-section {
    margin-top: var(--emu-common-spacing-large);

    @include mq('large') {
      margin-top: 36px;
      padding-top: 36px;
      padding-bottom: 36px;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-secondary-dark-gray-300);
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-secondary-dark-gray-300);
    }
  }

  &__dropdowns {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    @include mq('large') {
      gap: 30px;
      flex-direction: row;
      justify-content: space-between;
    }

    @include mq('x-large') {
      width: auto;
      flex: 1;
    }
  }

  &__button {
    color: var(--emu-common-colors-white);
    font-size: var(--emu-common-font-sizes-wide-medium);
    font-weight: var(--emu-common-font-weight-bold);
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 167px;
    z-index: var(--emu-common-other-z-index-layer);
    white-space: nowrap;
    transition: all 0.2s linear;
    -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

    @include mq('large') {
      padding-left: 33px;
      padding-right: 33px;
      width: 180px;
    }

    &-wrapper {
      background-color: var(--emu-common-colors-purple-200);
      border-radius: 25px;
      display: flex;
      position: relative;
      --active-count: 0;

      &::before {
        content: '';
        width: 167px; // from figma
        height: 100%;
        background-color: var(--emu-common-colors-white);
        position: absolute;
        top: 0;
        left: calc(var(--active-count) * 167px);
        border-radius: inherit;
        transition: all 0.2s linear;
        z-index: var(--emu-common-other-z-index-base);
        @include mq('large') {
          width: 180px;
          left: calc(var(--active-count) * 180px);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--emu-common-other-z-index-layer);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-purple-200);
        border-radius: inherit;
        pointer-events: none;
      }
    }

    &--active {
      color: var(--emu-common-colors-purple-300);
      transition-delay: 0.1s;
    }
  }

  // full class needed for increasing specificity
  .injectionpoints__select {
    &--line-type.injectionpoints__animation-item {
      opacity: 1;
      position: relative;
      pointer-events: initial;
    }

    &-wrapper {
      position: relative;
      max-width: 335px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        max-width: none;
        width: calc(50% - 15px);
      }
    }
  }

  &__image-disclaimer {
    margin-top: 17px;
    margin-bottom: 23px;

    @include mq('large') {
      margin-top: 36px;
      max-width: 750px;
      margin-bottom: var(--emu-common-spacing-none);
    }

    p {
      margin: var(--emu-common-spacing-none);

      font-size: 14px;
      line-height: var(--emu-common-line-heights-narrow-medium);
      letter-spacing: -0.2px;
      color: var(--emu-common-colors-purple-400);
      text-align: center;

      @include mq('large') {
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--emu-common-colors-black);
      }
    }
  }

  &__line-section {
    flex-direction: column;
    display: flex;

    @include mq('large') {
      flex-direction: row;
      gap: 73px;
    }

    &-wrapper {
      position: relative;
    }
  }

  &__line-description {
    margin-bottom: 12px;
    text-align: center;

    @include mq('large') {
      margin-bottom: 26px;
      text-align: left;
    }

    p {
      margin-bottom: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-none);

      a {
        text-decoration: none;
      }

      @include mq('large') {
        margin-bottom: 14px;
      }

      .emphasis {
        color: var(--emu-common-colors-purple-200);
        margin-left: 9px;
      }
    }

    h4 {
      margin-top: 20px;
      line-height: 30px;
      letter-spacing: -1px;
      color: var(--emu-common-colors-purple-300);

      @include mq('large') {
        margin-top: 47px;
        font-size: var(--emu-semantic-font-sizes-wide-large);
      }
    }
  }

  &__content-section {
    @include mq('large') {
      width: 407px; // as per figma
    }
  }

  &__image-section {
    @include mq('large') {
      flex: 1;
    }
  }

  &__pattern-items {
    img {
      width: 335px; // as per figma
      border-radius: var(--emu-common-border-radius-small);

      @include mq('large') {
        width: auto;
        border-radius: var(--emu-common-border-radius-medium);
      }
    }
  }

  &__pi-image {
    img {
      width: 248px; // as per figma
    }
  }

  &__pi-image,
  &__line-pattern-image,
  &__injection-point-image {
    .cmp-image {
      display: flex;
      justify-content: center;

      @include mq('large') {
        justify-content: left;
      }
    }
  }

  &__animation {
    &-wrapper {
      position: relative;

      > .injectionpoints__animation-item {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &.show {
          position: relative;
          pointer-events: initial;
          transition: all 0.4s linear;
          opacity: 1;
        }
      }
    }
  }
}
