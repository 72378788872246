.aaaem-carousel {
  padding-top: 68px;
  opacity: 0;

  @include aem-editor-view {
    opacity: 1;
  }

  &--show {
    opacity: 1;
  }

  // if the indicators animation is not needed
  &--static-indicators {
    padding-top: var(--emu-common-spacing-none);
    opacity: 1;

    &[data-controls='true'] {
      padding-left: 60px;
      padding-right: 60px;
    }

    .aaaem-carousel {
      &__indicators {
        position: static;
        width: auto;
        border-radius: var(--emu-common-border-radius-none);
        background-color: var(--emu-common-colors-transparent);

        &::after {
          display: none;
        }
      }

      &__indicator {
        flex: initial;
        background-color: var(
          --emu-component-containers-carousel-indicators-dots-color-background-default-dark
        );
        &.tns-nav-active {
          background-color: var(
            --emu-component-containers-carousel-indicators-dots-color-background-active-dark
          );
        }
      }

      &__actions {
        position: static;
      }

      &__action {
        position: absolute;
        transform: translateY(50%);
      }
    }
  }

  &[data-controls='false'] {
    .aaaem-carousel {
      &__indicators {
        width: 100%;
      }
    }
  }

  &__indicators {
    position: absolute;
    top: 16px;
    left: 0;
    width: calc(
      100% - 40px - 40px - 15px - 32px
    ); // 40px - next/prev control size, 15px - gap between next/prev controls, 32px - gap between controls and indicator
    border-radius: var(--emu-common-border-radius-small);
    overflow: hidden;
    background-color: var(
      --emu-component-containers-carousel-indicators-dots-color-background-default-dark
    );

    &::after {
      content: '';
      width: var(--width, 40px);
      height: 100%;
      background-color: var(
        --emu-component-containers-carousel-indicators-dots-color-background-active-dark
      );
      position: absolute;
      top: 0;
      left: var(--left, 0);
      transition: left 0.4s ease;
      border-radius: var(--emu-common-border-radius-small);
      overflow: hidden;
    }
  }

  &__indicator {
    flex: 1;
    border-radius: var(--emu-common-border-radius-small);
    background-color: var(--emu-common-colors-transparent);

    &.tns-nav-active {
      background-color: var(--emu-common-colors-transparent);
    }
  }

  // action wrapper
  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 15px;
    z-index: var(--emu-common-other-z-index-layer);
  }

  // individual action icon
  &__action {
    width: 40px; // to match the design
    height: 40px; // to match the design
    border-radius: 50%;
    position: static;

    &[disabled] {
      opacity: 0.5;
    }

    svg {
      position: relative;
      left: -1px;
      top: 3px;
      stroke: var(
        --emu-component-containers-carousel-controls-icon-color-fill-light
      );
    }

    &-next {
      svg {
        left: 1px;
      }
    }
  }

  .tns-gallery {
    .tns-item {
      @include aem-editor-view {
        &:not(.tns-slide-active) {
          display: none;
        }
      }
    }
  }
}
