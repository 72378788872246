//header light theme styling
.header--light {
  div.header {
    .header {
      &__menu-mobile,
      &__menu-mobile-dropdown-top-content {
        > div.image {
          &:first-child {
            display: none;
          }
        }
      }

      &__menu-mobile {
        &-dropdown {
          > .button {
            .aaaem-button {
              background: var(--emu-common-colors-white);
              color: var(--emu-common-colors-purple-300);
            }
          }
        }

        &-logo {
          display: none;

          &-light {
            display: block;
          }
        }
      }
    }

    .emu-navigation {
      &__content-wrapper {
        nav {
          ul {
            li {
              &.emu-navigation__item--active {
                &::after {
                  @include mq('large') {
                    background-color: var(--emu-common-colors-white);
                  }
                }

                a,
                a:hover {
                  @include mq('large') {
                    color: var(--emu-common-colors-white);
                  }
                }
              }

              a {
                @include mq('large') {
                  color: var(--emu-common-colors-white);
                }
              }
            }
          }
        }
      }
    }

    // Overriding header--light styles when the header is sticky.
    .header--sticky {
      .emu-navigation {
        &__content-wrapper {
          nav {
            ul {
              li {
                &.emu-navigation__item--active {
                  &::after {
                    @include mq('large') {
                      background-color: var(
                        --emu-common-colors-purple-300
                      );
                    }
                  }

                  a,
                  a:hover {
                    @include mq('large') {
                      color: var(--emu-common-colors-purple-300);
                    }
                  }
                }

                a {
                  @include mq('large') {
                    color: var(--emu-common-colors-purple-300);
                  }
                }
              }
            }
          }
        }
      }

      .header__menu-mobile-dropdown {
        > .button {
          .aaaem-button {
            @include mq('large') {
              background-color: var(--emu-common-colors-purple-200);
              color: var(--emu-common-colors-white);
            }
          }
        }
      }
    }
  }
}
