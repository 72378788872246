.flippable-card {
  margin-bottom: 10px;
  margin-top: 10px;

  &__wrapper {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.2s linear, opacity 0.4s linear;
    border-radius: var(--emu-common-border-radius-medium);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;

    @include aem-editor-view {
      opacity: 1;
    }

    &--show {
      opacity: 1;
    }

    &--flipped {
      transform: rotateY(180deg);
    }
  }

  &__side {
    height: 100%;
    width: 100%;
    border-radius: var(--emu-common-border-radius-medium);
    backface-visibility: hidden;
    overflow: hidden;

    &--absolute {
      position: absolute;
      top: 0;
    }
  }

  &__front {
    &:hover {
      background-color: var(--emu-common-colors-purple-100);
    }
  }

  &__back {
    background-color: var(--emu-common-colors-purple-300);
    transform: rotateY(180deg);

    [data-component='text']p {
      color: var(--emu-common-colors-white);
    }
  }
}

// author mode styles
.flippable-card,
.flippable-card__side,
.flippable-card__side .front-card,
.flippable-card__side .back-card,
.flippable-card__side .back-card > div,
.flippable-card__side .front-card > div {
  @include aem-editor-view {
    position: static !important;
    transform: none !important;
    height: auto !important;
    min-height: 0 !important;
  }
}
